<template>
  <div class="appWidth">
    <div class="mdx-relative bannerBoxsss">
      <el-carousel trigger="click" height="605px">
        <el-carousel-item v-for="(item,index) in bannerList" :key="index">
<!--          <el-image style="width: 100%; height: 100%;cursor: pointer;" :src="item.imgUrl" fit="fill"-->
<!--            @click="$mHelper.to('/imageTextDetail?types=1&id='+item.id)"></el-image>-->
            <el-image style="width: 100%; height: 100%;cursor: pointer;" :src="item.imgUrl" fit="fill"></el-image>
        </el-carousel-item>
      </el-carousel>
      <div class="searchBox">
		 <div class="search-content boxCenter">
			<div class="search-top">
				<div class="title">专业的一站式工程服务平台</div>
				<div class="info">提供线上一站式服务，高效、合理配置人力资源，满足企业对高素质造价师的迫切需求，弥合行业用人的波峰波谷，帮助企业降本增效。</div>
			</div>	
			<el-input @keyup.enter.native="$mHelper.to('/search')" placeholder="找人才，找项目，就来中一鼎合造价众包平台" placeholder-class="placeholderClass" v-model="searchText">
			  <i slot="prefix" class="el-input__icon el-icon-search" style="color: #185BA1;"></i>
			  <template slot="append">
			    <el-button type="primary" style="background-color: #4077DD; color: #FFFFFF ;" @click="$mHelper.to('/search')">搜索</el-button>
			  </template>
			</el-input>
			<div class="search-statis">
				<div class="statis-box">
					<div class="statis-num">{{statisInfo.projectCount || '0'}}个</div>
					<div class="statis-title">项目数</div>
				</div>
				<div class="statis-box">
					<div class="statis-num">{{statisInfo.engineerCount || '0'}}名</div>
					<div class="statis-title">注册工程师</div>
				</div>
				<div class="statis-box">
					<div class="statis-num">{{statisInfo.fwUserCount || '0'}}个</div>
					<div class="statis-title">服务客户</div>
				</div>
			</div> 
		 </div>
      </div>
    </div>
    <div class="nav-box boxCenter">
		<div class="top-nav">
			<div class="nav-item nav-item1"  @click="zcGcsTap('/release')">
				<img src="../../static/images/home/top_nav1@2x.png"/>
				<div class="title">发布需求</div>
			</div>
			<div class="nav-item nav-item2"  @click="$mHelper.to('/assess')">
				<img src="../../static/images/home/top_nav2@2x.png"/>
				<div class="title">智能估价</div>
			</div>
			<div class="nav-item nav-item2"  @click="zcGcsTap('/EngineerRegist')">
				<img src="../../static/images/home/top_nav3@2x.png"/>
				<div class="title">注册工程师</div>
			</div>
		</div>
		<div class="process-box">
			<div class="home-tbox">
				<div class="left">
					<img src="../../static/images/home/title_icon@2x.png"/>
					<div class="home-title">平台流程</div>
				</div>
				<div class="right"></div>
			</div>
			<img src="../../static/images/home/liuc@2x.png" />
		</div>
	</div>
	<div class="project-box">
		<div class="boxCenter">
			<div class="home-tbox" style="padding-top: 64px;">
				<div class="left">
					<img src="../../static/images/home/title_icon@2x.png"/>
					<div class="home-title">最新项目</div>
				</div>
				<div class="right">
					 <div class="proj-right" @click="$mHelper.to('/hall')"> 更多项目<i class="el-icon-d-arrow-right" style="color: rgba(0,0,0,0.45);"></i></div>
				</div>
			</div>
			<div class="project-content">
				<div class="left">
					<div class="left-item" v-for="(item,index) in majorList" :key="index + 'b'"  @click="activeTab(item.id)">
						<div class="active-left" v-if="active_left==item.id" ></div>
						<div class="left-title ellipsis"> {{ item.name }}</div>
					</div>
				</div>
				<div class="right">
					<div class="project-item" v-for="(item,index) in projectList" :key='index' @click="toDetails(item)">
					   <div class="item-left" :class="item.status==3 ? 'itemStatus':''">
						   <div class="status-lines">
								<div class="status-btn1" v-if="item.status==3">报名中</div>
								<div class="status-btn2" v-else-if="item.status==4">项目进行中</div>
								<div class="status-btn3" v-else-if="item.status==5">圆满完成</div>
								<div class="status-btn4" v-else-if="item.status==9">已取消</div>
						   </div>	
						   <div class="title-lines ellipsis">
							   {{item.regionFy}}/{{item.typeFy}}
							   <template>
								 <span v-for="(row,index) in item.majorFy" :key="index+'q'">/{{row.name}}</span>
							   </template>
							   <span>
								 /总投资:￥{{item.totalInvestment||'00'}}
							   </span>
							   <span>
								 /总规模:{{item.totalScale}}m²
							   </span>
							   /{{item.serviceContentFy}}
						   </div>
					   </div>
					   <div class="item-right" v-if="item.status==3">
						     {{ limitTimeFilter(item.fbTime,item.bmTime) }}
					   </div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="app-box">
		<div class="app-content boxCenter">
			<div class="left">
				<div class="app-left-title">中一鼎合造价众包APP</div>
				<div class="app-left-info">专业人士都在用的工程咨询交易平台</div>
			</div>
			<div class="right">
				<div class="down-box">
					<img :src="appCodeImg"/>
					<div class="down-title">IOS端 扫码下载</div>
				</div>
				<div class="down-box">
					<img :src="appCodeImg"/>
					<div class="down-title">安卓端 扫码下载</div>
				</div>
			</div>
		</div>
	</div>
	<div class="trends-box">
		<div class="boxCenter">
			<div class="home-tbox" style="padding-top: 64px;">
				<div class="left">
					<img src="../../static/images/home/title_icon@2x.png"/>
					<div class="home-title">最新动态</div>
				</div>
				<div class="right">
					 
				</div>
			</div>
			<div class="trends-content">
				<div class="dt_box_left" v-if="zixunList.length>0" @click="$mHelper.to('/imageTextDetail?types=9&id='+zixunList[0].id)">
					<img style="width: 100%;height: 200px;" :src="zixunList[0].img">
					<div class="dt_lines mdx-flex wzk-cursor" style="margin-top: 20px;">
						<div class="line1" style="flex: 1;">
							<h3>{{zixunList[0].title}}</h3>
						</div>
						<div> <i class="el-icon-d-arrow-right" style="color: #666;"></i></div>
					</div>
					<div class="mdx-flex listLines">
						<div class="left">
							<div>
							  <h2>{{zixunList[0].years}}</h2>
							</div>
							<div class="times">{{zixunList[0].days}}</div>
						</div>
						<div class="xians"></div>
						<div class="contentText line2">
							{{zixunList[0].brief||'暂无介绍'}}
						</div>
					</div>
				</div>
				<div class="dt_box_right ">
					<div class="mdx-flex listLines2" @click="$mHelper.to('/imageTextDetail?types=9&id='+item.id)" v-for="(item,index) in zixunList1" :key='index'>
						<div class="left">
							<div>
							  <h2>{{item.years}}</h2>
							</div>
							<div class="times">{{item.days}}</div>
						</div>
						<div class="xians"></div>
						<div class="contentText line2">
							<div class="titlesLines">{{item.title}}</div>
							<div class="neirong">
							  {{item.brief||'暂无介绍'}}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="about-box">
		<div class="boxCenter">
			<div class="home-tbox" style="padding-top: 64px;">
				<div class="left">
					<img src="../../static/images/home/title_icon@2x.png"/>
					<div class="home-title">关于我们</div>
				</div>
				<div class="right"></div>
			</div>
			<div class="about-content">
				 <div class="left">
					<div class="">
						<div class="info">北京中一鼎合工程管理有限公司成立于2003年，拥有工程造价咨询资质、招标代理资质、工程监理等多项资质，是一家具备全过程工程咨询能力的专业机构。</div>
						<div class="info">公司自主研发设计的“中一鼎合造价众包平台”，主要针对工程造价咨询企业面临的人员需求波动问题，通过线上一站式服务，高效、合理配置人力资源，满足企业业务高峰时对高素质造价师的迫切需求，弥合行业用人的波峰波谷，帮助企业降本增效 。</div>
					  　<div class="info">我们始终坚持“质量第一、服务至上”的宗旨，以一流的专业团队、扎实的专业知识和丰富的执业经验，一如既往地为广大客户提供优质、高效、多方位、全过程的工程咨询服务。</div>
					</div>
					<div class="about-btn-list">
						<el-button type="primary" class="about-btn" @click="$mHelper.otherTo('/xieyi?type=3')">服务规则</el-button>
					    <el-button type="text" class="about-btn2" @click="$mHelper.to('/xieyi?type=4')">计费规则</el-button>
					</div>
				</div>
				<div class="right">
					<img :src="sfImg">
				</div>
			</div>
		</div>
	</div>
	<div class="advantage-box">
		<div class="boxCenter">
			<div class="home-tbox" style="padding-top: 64px;">
				<div class="left">
					<img src="../../static/images/home/title_icon@2x.png"/>
					<div class="home-title">平台优势</div>
				</div>
				<div class="right"></div>
			</div>
			<div class="advantage-content">
				<div class="adv-top">
					汇聚全国各地各专业优秀工程师，极速响应、标准化作业，专人全程项目管理，资深专家质控审核，工程造价咨询资质，价格合理、运作透明、服务规范，不交会员费、合规节税，一站式服务。
				</div>
				<div class="adv-img-box">
					<img class="adv-img" src="../../static/images/home/center-bg@2x.png" />
				</div>
				<div class="advantage-list">
					<div class="adv-line1">
						<div class="adv-item">
							<img src="../../static/images/home/center_1@2x.png" />
							<div class="adv-title">工程造价一站式线上服务</div>
							<div class="adv-info">发布需求、匹配工程师、价格估算、在线结算、质保管理</div>
						</div>
						<div class="adv-item">
							<img src="../../static/images/home/center_2@2x.png" />
							<div class="adv-title">项目、人才资源的配置和优化整合</div>
							<div class="adv-info">自动配置、被动配置</div>
						</div>
					</div>
					<div class="adv-line2">
						<div class="adv-item">
							<img src="../../static/images/home/center_1@2x.png" />
							<div class="adv-title">互联网模式促进效益</div>
							<div class="adv-info">缩减时间成本、快速匹配</div>
						</div>
						<div class="adv-item">
							<img src="../../static/images/home/center_2@2x.png" />
							<div class="adv-title">专业人做专业事情</div>
							<div class="adv-info">汇聚各地各专业优秀工程师，项目专业可细分拆分、精准匹配对口工程师</div>
						</div>
					</div>
					<div class="adv-line3">
						<div class="adv-item">
							<img src="../../static/images/home/center_1@2x.png" />
							<div class="adv-title">降本增效助企业</div>
							<div class="adv-info">快速整合、减少企业用工维护成本、提高企业利润空间</div>
						</div>
						<div class="adv-item">
							<img src="../../static/images/home/center_2@2x.png" />
							<div class="adv-title">促灵活用工</div>
							<div class="adv-info">吸纳社会工程师加入平台，扩充作业能力、价格合理、运作透明、工程师收益占比高，收入完税</div>
						</div>
					</div>
					<div class="adv-line4">
						<div class="adv-item">
							<img src="../../static/images/home/center_3@2x.png" />
							<div class="adv-title">质量第一，严守质量品质</div>
							<div class="adv-info">专业项目经理全程管理，专业指控团队严格把守作业质量</div>
						</div>
						<div class="adv-item">
							<img src="../../static/images/home/center_3@2x.png" />
							<div class="adv-title">安心无忧，无忧服务</div>
							<div class="adv-info">项目经理服务，银行合作减少资金压力，需求把关，作业审核</div>
						</div>
						<div class="adv-item">
							<img src="../../static/images/home/center_3@2x.png" />
							<div class="adv-title">信誉保障，资金安全</div>
							<div class="adv-info">按市场化规则，内部结算，银行资金监管，合作点对点直接到账</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="service-box">
		<div class="boxCenter">
			<div class="home-tbox" style="padding-top: 64px;">
				<div class="left">
					<img src="../../static/images/home/title_icon@2x.png"/>
					<div class="home-title">服务范围</div>
				</div>
				<div class="right"></div>
			</div>
			<div class="service-content">
				<div class="service-top">坚持“质量第一、服务至上”的宗旨，以一流的专业团队、扎实的专业知识和丰富的执业经验， 一如既往地为广大客户提供优质、高效、多方位、全过程的工程咨询服务。</div>
				<div class="service-list">
					<div style="display: flex; justify-content: space-between; margin-top: 24px;">
						<div class="service-item">
							<div class="service-title">工程算量、编清单、组价</div>
							<!-- <div class="service-info">服务文本解释详细说明，让用户了解该服务的详细信息，而不是让他去猜测，思考</div> -->
						</div>
						<div class="service-item">
							<div class="service-title">施工图预算、标底、控制价</div>
							<!-- <div class="service-info">服务文本解释详细说明，让用户了解该服务的详细信息，而不是让他去猜测，思考</div> -->
						</div>
					</div>
					<div style="display: flex; justify-content: space-between; margin-top: 48px;">
						<div class="service-item">
							<div class="service-title">投标报价、工程结算</div>
							<!-- <div class="service-info">服务文本解释详细说明，让用户了解该服务的详细信息，而不是让他去猜测，思考</div> -->
						</div>
						<div class="service-item">
							<div class="service-title">概算、估算</div>
							<!-- <div class="service-info">服务文本解释详细说明，让用户了解该服务的详细信息，而不是让他去猜测，思考</div> -->
						</div>
					</div>
					<div class="service-item" style="margin-top: 48px;">
						<div class="service-title">审核业务</div>
						<!-- <div class="service-info">服务文本解释详细说明，让用户了解该服务的详细信息，而不是让他去猜测，思考</div> -->
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="case-box">
		<div class="boxCenter">
			<div class="home-tbox" style="padding-top: 64px;">
				<div class="left">
					<img src="../../static/images/home/title_icon@2x.png"/>
					<div class="home-title">项目案例</div>
				</div>
				<div class="right"></div>
			</div>
			<div class="case-content mdx-flex mdx-row-between">
				<div @click="$refs.anliCarousel.prev()">
				    <i class="el-icon-arrow-left"></i>
				</div>
				<div class="swerpr">
				    <el-carousel :interval="4000" ref="anliCarousel" :autoplay='false' arrow="never" indicator-position='none'>
						<el-carousel-item v-for="(item,index) in caseList" :key="index">
						  <div class="mdx-flex mdx-row-between">
							<div class="anliBox" v-for="(row,rowIndex) in item" :key="rowIndex"
							  @click="$mHelper.to('/imageTextDetail?id='+row.id+'&types=8')">
							  <img :src="row.img">
							  <div class="names line1">
								{{row.title}}
							  </div>
							</div>
							<template v-if="item.length%4!=0">
							  <div class="anliBox youqing" v-for="(row,rowIndex) in (4-(item.length%4))" :key="rowIndex+'m'"
								@click="toOthenWeb(row)">
							  </div>
							</template>
						  </div>
						</el-carousel-item>
					</el-carousel>
				</div>
				<div @click="$refs.anliCarousel.next()"><i class="el-icon-arrow-right"></i></div>
			</div>
		</div>
	</div>
	<div class="links-box">
		<div class="boxCenter">
			<div class="home-tbox" style="padding-top: 64px;">
				<div class="left">
					<img src="../../static/images/home/title_icon@2x.png"/>
					<div class="home-title">友情链接</div>
				</div>
				<div class="right"></div>
			</div>
			<div class="links-content mdx-flex mdx-row-between">
				<div @click="$refs.otherWeb.prev()" class="wzk-cursor">
				  <i class="el-icon-arrow-left" style="font-size: 20px;color: #666;"></i>
				</div>
				<div class="swerpr2">
				  <el-carousel ref="otherWeb" :interval="4000" arrow="never" indicator-position='none' height="100px">
				    <el-carousel-item v-for="(item,index) in blogroll" :key="index" height="100px">
				      <div class="mdx-flex mdx-row-between">
				        <div class="anliBox youqing" v-for="(row,rowIndex) in item" :key="rowIndex" @click="toOthenWeb(row)">
				          <img style="width: 221px;height: 110px;" :src="row.img">
				        </div>
				        <template v-if="item.length%5!=0">
				          <div class="anliBox youqing" v-for="(row,rowIndex) in (5-(item.length%5))" :key="rowIndex+'n'"
				            @click="toOthenWeb(row)">
				          </div>
				        </template>
				      </div>
				    </el-carousel-item>
				  </el-carousel>
				</div>
				<div @click="$refs.otherWeb.next()" class="wzk-cursor"><i class="el-icon-arrow-right" style="font-size: 20px;color: #666;"></i></div>
			</div>
		</div>
	</div>
	<div class="bottom-box">
		<div class="btn-fabu" @click="zcGcsTap('/release')">立即发布项目</div>
	</div>

	<!--初始登录-->
	<el-dialog title="" :visible.sync="paswordShow" :before-close="closePwd" width="700" center class="home-tip">
		<div class="title">登录成功，可使用手机号或者账号密码登录系统。</div>
		<div class="title">建议初次登录后修改密码 </div>
		<div class="info w-m-t-20">您的账号信息如下：</div>
		<div class="info">登录账号：{{userInfo.mobile}}</div>
		<div class="info">登录密码：888888</div>
		<span slot="footer" class="dialog-footer">
			<el-button class="footer-btn-left" @click="closePwd">暂不修改</el-button>
			<el-button type="primary footer-btn-right" @click="$mHelper.to('/SecuritySet')">修改密码</el-button>
		</span>
	</el-dialog>

    <el-dialog
      title=""
      :visible.sync="centerDialogVisible"
      width="50%"
      center>
      <div class="textStyle">请选择发布模式 </div>
      <div class="textStyle">（首次使用建议选择便捷发布）</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="convenientRelease">便捷发布</el-button>
        <el-button type="primary" @click="releaseFn">发布</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        centerDialogVisible:false,
        searchText: '',
        bannerList: [],
        advantageBannerList: [], //平台有事
        serviceList: [], //服务范围图片
        lcList: [{
          text: '发布需求',
          img: require('../../static/images/lcImg/lc1.png')
        }, {
          text: '需求审核',
          img: require('../../static/images/lcImg/lc2.png')
        }, {
          text: '签约付款',
          img: require('../../static/images/lcImg/lc3.png')
        }, {
          text: '派单作业',
          img: require('../../static/images/lcImg/lc4.png')
        }, {
          text: '平台质控',
          img: require('../../static/images/lcImg/lc5.png')
        }, {
          text: '验收完成',
          img: require('../../static/images/lcImg/lc6.png')
        }, {
          text: '质保期',
          img: require('../../static/images/lcImg/lc7.png')
        }],
        superiorityList: [],
        enterpriseInfo: {},
        blogroll: [], //友情链接
        caseList: [], //项目案例
        projectList: [], //最新项目
        zixunList: [], //最新资讯
        zixunList1: [], //最新资讯
        queryParams: {
          page: 1, //当前页数
          limit: 5, //每页条数
          totalPage: 1
        },
        ratesText: '',
        sfImg: '',
		active_left: 1,
		majorList:[],
		wxCodeImg: '',
		appCodeImg:'',
		statisInfo: {},
		paswordShow: false,
      }
    },
    computed: {
      userInfo() {
        return this.$store.getters['userInfo']
      }
    },
    created() {
      if (this.$store.state['token']) {
        this.$uniApi.Logins(); //获取个人用户信息
        this.$http.get('notice/wdNum', ).then(res => {
          if (res.code == 200) {
            this.$store.state.msgNum = parseInt(res.data.projectNum) + parseInt(res.data.xtNum)
          }
        })
      }
	  this.$http.post('project/statistics').then(res => {
	    if (res.code == 200) {
			this.statisInfo = res.data;
	    }
	  })
	  
	  this.$http.get('config/pcInfo').then(res => {
	    if (res.code == 200) {
	      this.wxCodeImg = res.data.qr
	      this.appCodeImg = res.data.app
	    }
	  })
	  
      //获取轮播图
      this.getBannerList();
      //this.getsuperList(); //平台优势
      this.getEnterInfo(); //企业累计信息
      this.getBlogroll(); //友情链接
      this.getCaseList();
      this.getMajors(); 
      this.getZixunList() //获取最新资讯
      this.getSFImg();
      
      //收费标砖
      this.$http.get('config/getByKey', {
        configKey: 'charging_standard'
      }).then(res => {
        if (res.code == 200) {
          this.ratesText = res.data
        }
      })
      this.setKeyWords();
    },
	updated() {
	   //查看用户状态
	   if(this.userInfo.passWordIs == 0 && this.userInfo.identity!=3 && this.paswordShow == false){
		   if(localStorage.getItem('padShow')!=1){
			    this.paswordShow = true;
		   }
	   }
	},
    methods: {
		closePwd(){
			this.paswordShow=false;
			localStorage.setItem('padShow',1);
			
		},
		//报名有效期
		limitTimeFilter(fbTime,val) {
			if(val == null || fbTime == null){
				val = 0;
				fbTime = 0;
			}
			let time = new Date().getTime();
			fbTime = Date.parse(fbTime);
			
			val =  ( fbTime + val*60*60*1000 ) - time;
			if(val=='' || val < 0){
				val = 0;
			}
			if (val <= 0) {
				return "";
			} else {
				var days = Math.floor(val / (1000 * 60 * 60 * 24));
				var lefth = Math.floor((val / (1000 * 60 * 60)) % 24);
				var leftm = Math.floor((val / (1000 * 60)) % 60);
				var lefts = Math.floor((val / 1000) % 60);
				return "报名还剩"+ days + "天" +lefth + "时" + leftm + "分";
				//return "报名还剩"+ days + "天" +lefth + "时" + leftm + "分" + lefts + "秒";
			}
		},
		activeTab(e){
			this.active_left = e;
			this.getProjectList(e);
		},
      getSFImg() {
        this.$http.get('config/getByKey', {
          configKey: 'sf_img'
        }).then(res => {
          if (res.code == 200) {
            this.sfImg = res.data.img
          }
        })
      },
      bannerTap() {
        console.log(1)
        alert(1236);
      },
      
      zcGcsTap(path) {
        if(!this.userInfo.identity){
          this.$router.push({
            path: '/login'
          })
        }
		if (this.userInfo.identity == 3) {
		  this.$message.error('经理没有权限发布需求或者注册工程师')
		  return
		}
		
		if (path == '/EngineerRegist') {
		  if (this.userInfo.identity == 2) {
		    this.$message.error('您当前已是工程师，请勿重复注册工程师')
		    return
		  }
		  
		  if(this.userInfo.bindAuthId != null){
			  this.$message.error('企业用户不能注册工程师')
			  return
		  }
		  
		  if(this.userInfo.rzStatus ==1 || this.userInfo.rzStatus ==2){
			  this.$message.error('已经企业认证或者申请中不能注册工程师')
			  return
		  }
		  
		   this.$mHelper.to(path)
		}
		
		if(path == '/release'){
			if(this.userInfo.identity == 2 && localStorage.getItem('changeType') == 2){
			  this.$message.warning('请先切换为客户身份');
			  return
			}
			this.centerDialogVisible = true;
		}
       
      },
        //便捷发布
      convenientRelease(){
        this.centerDialogVisible = false;
        this.$router.push({
          path:'/release',
          query:{
            releaseType: 1
          }
        })
      },
      // 发布
      releaseFn(){
        this.centerDialogVisible = false;
        this.$router.push({
          path:'/release',
          query:{
            releaseType: 2 
          }
        })
      },
      getBannerList() {
        //顶部轮播图
        this.$http.get('banner/getBanner', { //
          showScene: 1
        }).then(res => {
          if (res.code == 200) {
            this.bannerList = res.data
          }
        })
        //平台优势轮播图
        this.$http.get('banner/getBanner', {
          showScene: 2
        }).then(res => {
          if (res.code == 200) {
            this.advantageBannerList = res.data
          }
        })
        //服务范围图片
        this.$http.get('banner/getBanner', {
          showScene: 6
        }).then(res => {
          if (res.code == 200) {
            this.serviceList = res.data
          }
        })
      },
      //平台优势文本
      getsuperList() {
        //平台优势文本
        this.$http.get('platform/list', {
          type: 1
        }).then(res => {
          if (res.code == 200) {
            this.superiorityList = res.data
          }
        })
      },
      //企业累计信息
      getEnterInfo() {
        //企业累计信息
        this.$http.get('case/getWetchCumList', {
          type: 1
        }).then(res => {
          if (res.code == 200) {
            this.enterpriseInfo = res.data
          }
        })
      },
      //
      getBlogroll() {
        this.$http.get('/case/links', {
          pageNum: 1,
          pageSize: 99999
        }).then(res => {
          if (res.code == 200) {
            let list = []
            for (let i = 0; i < res.data.list.length; i += 5) {
              list.push(res.data.list.slice(i, i + 5));
            }
            this.blogroll = list
            // console.log(list);
          }
        })
      },
      getCaseList() {
        const loading = this.$loading({
          lock: true,
          text: '加载中...',
          spinner: 'el-icon-loading',
          background: 'rgba(255, 255, 255, 0.8)'
        });

        this.$http.get('case/list', {
          pageNum: 1,
          pageSize: 999,
        }).then(res => {
          if (res.code == 200) {
            // this.queryParams.totalPage = res.data.pages
            let list = []
            for (let i = 0; i < res.data.list.length; i += 4) {
              list.push(res.data.list.slice(i, i + 4));
            }
            this.caseList = list
          } else {
            // this.caseList = []
          }
          loading.close();
        }).catch(err => {
          loading.close();
        })
      },
	  //专业类型获取
	  getMajors(){
		  this.$http.get("dict_data/getMajors").then((res) => {
		    if (res.code == 200) {
		      this.majorList = res.data;
		  		  this.active_left = res.data[0].id;
		  		  this.getProjectList(res.data[0].id);
		    } else {
		      this.$message.error("专业获取失败");
		    }
		  });
	  },
      getProjectList(e) { //最新项目
	    this.projectList = [];
        this.$http.post('project/newlist', {
          pageNum: 1,
          pageSize: 10,
		  sortRule: 0,
		  tmajor: e,
        }).then(res => {
          if (res.code == 200) {
			 this.projectList =  res.data.list;
			
            // res.data.list.forEach((e, index) => {
            //   if (index % 2 == 0) {
            //     this.projectList.listOne.push(e)
            //   } else {
            //     this.projectList.listTow.push(e)
            //   }
            // })
           
          }
        })
      },
      getZixunList() {
        this.$http.get('information/list', {
          pageNum: 1,
          pageSize: 4
        }).then(res => {
          if (res.code == 200) {
            let list = []
            res.data.list.forEach((e, index) => {
              e.years = e.createTime.slice(0, 4)
              e.days = e.createTime.slice(5, 10)
              if (index != 0) {
                list.push(e)
              }
            })
            console.log(res.data)

            this.zixunList = res.data.list;
            this.zixunList1 = list;
          }
        })
      },
      toDetails(item) {
        this.$mHelper.to('/hallDetail?id=' + item.id)
      },
      toOthenWeb(item) {
        // window.open(item.url)
        this.$mHelper.otherTo(item.url)
      },
      setKeyWords() {
        let element = document.getElementsByTagName('meta');
        let elementArr = Array.from(element);
        let keyWordMeta = elementArr.find(item=>item.name=='keywords');
        if (keyWordMeta) {
          keyWordMeta.content = "中一鼎合造价众包平台,中一鼎合线上业务,中一鼎合,造价咨询平台,中一鼎合工程管理有限公司,zhongyidinghe.cn,灵活用工,算客,广联达,线上平台,青矩,房屋土建,钢结构,精装修,幕墙,房建安装,工程量计量,钢筋算量,土建算量,安装算量,工程量清单,控制价服务,精装算量,市政土建 市政管网,市政电气,市政智能化,编清单,组价,概算,一站式造价咨询服务,造价急活,中一鼎合官网,造价咨询线上平台";
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
::v-deep .el-dialog{
		width: 414px !important;
		height: 235px;
		background: #FFFFFF;
		border-radius: 10px;
		backdrop-filter: blur(42px);
		.textStyle{
			font-family: PingFangSC, PingFang SC;
			font-weight: 500;
			font-size: 16px;
			color: rgba(0,0,0,0.85);
			line-height: 36px;
			text-align: left;
			font-style: normal;
		}
		.dialog-footer{
			display: flex;
			justify-content: center;
			.footer-btn-left{
				width: 96px;
				height: 40px;
				background: #FFFFFF;
				border-radius: 6px;
				border: 1px solid #4077DD;
				font-family: PingFangSC, PingFang SC;
				font-weight: 400;
				font-size: 16px;
				color: #4077DD;
				line-height: 24px;
				text-align: center;
				font-style: normal;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			.footer-btn-right{
				width: 96px;
				height: 40px;
				background: #4077DD;
				border-radius: 8px;
				font-family: PingFangSC, PingFang SC;
				font-weight: 400;
				font-size: 16px;
				color: #FFFFFF;
				line-height: 24px;
				text-align: center;
				font-style: normal;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-left: 24px;
			}
		}
		
	}


.bannerBoxsss {
	width: 100%;
	height: 605px;
	margin-left: auto;
	margin-right: auto;
	::v-deep .el-carousel__arrow {
	  height: 50px !important;
	  width: 50px !important;
	  z-index: 9999999999;
	}

	::v-deep .el-carousel__indicators--horizontal {
	  .el-carousel__indicator--horizontal button {
		width: 10px;
		height: 10px;
		background: rgba(255, 255, 255, 0.7);
		border-radius: 50%;
	  }

	  .el-carousel__indicator--horizontal.is-active button {
		width: 10px;
		height: 10px;
		background: #ffffff;
		border-radius: 10px;
	  }
	}
	
	.searchBox {
	    position: absolute;
	    top: 10px;
	    z-index: 99998;
	    width: 100%;
	    display: flex;
	    justify-content: center;
		.search-content{
		
			.search-top{
				.title{
					font-family: PingFangSC, PingFang SC;
					font-weight: 600;
					font-size: 36px;
					color: rgba(0,0,0,0.85);
					line-height: 54px;
					text-align: left;
					font-style: normal;
					margin-top: 101px;
				}
				.info{
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: rgba(0,0,0,0.85);
					line-height: 22px;
					text-align: left;
					font-style: normal;
					margin-top: 20px;
				}
			}
			.placeholderClass {
				font-family: PingFangSC, PingFang SC;
				font-weight: 400;
				font-size: 16px;
				color: #BFBFBF;
				line-height: 24px;
				text-align: left;
				font-style: normal;
			}
			::v-deep .el-input-group {
				width: 590px;
				margin-top: 47px;
				.el-input__inner {
					color: #4077DD;
					border: 1px solid #4077DD;
				}
				.el-input-group__append {
					border: 1px solid #4077DD;
					background-color: #4077DD;
				}
				::-webkit-input-placeholder {
					color: #BFBFBF;
				}
				::-moz-input-placeholder {
					color: #BFBFBF;
				}
				::-ms-input-placeholder {
					color: #BFBFBF;
				}
			}
			.search-statis{
				display: flex;
				justify-content: flex-start;
				align-items: center;
				margin-top: 35px;
				.statis-box{
					margin-right: 108px;
					.statis-num{
						font-family: PingFangSC, PingFang SC;
						font-weight: 600;
						font-size: 24px;
						color: #4077DD;
						line-height: 33px;
						text-align: center;
						font-style: normal;
					}
					.statis-title{
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 16px;
						color: rgba(0,0,0,0.85);
						line-height: 14px;
						text-align: left;
						font-style: normal;
						margin-top: 8px;
					}
				}
			}
			
		}
	
	}
	
}

.nav-box{
	.top-nav{
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 64px;
		.nav-item{
			width: 384px;
			height: 106px;
			background: #FFFFFF;
			box-shadow: 0px 6px 10px 6px rgba(44,46,51,0.03), 6px 6px 20px 3px rgba(44,46,51,0.06);
			border-radius: 4px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			cursor: pointer;
			img{
				width: 58px;
				height: 58px;
				margin-left: 24px;
			}
			.title{
				font-family: PingFangSC, PingFang SC;
				font-weight: 600;
				font-size: 24px;
				color: rgba(0,0,0,0.85);
				line-height: 33px;
				text-align: left;
				font-style: normal;
				margin-left: 24px;
			}
		}
		.nav-item1{
			background-image: url('../../static/images/home/bg1@2x.png');
			background-size: 100% 100%;
		}
		.nav-item2{
			background-image: url('../../static/images/home/bg2@2x.png');
			background-size: 100% 100%;
		}
		.nav-item3{
			background-image: url('../../static/images/home/bg3@2x.png');
			background-size: 100% 100%;
		}
	}
	.process-box{
		margin-top: 64px;
		img{
			max-width: 100%;
		}
	}
}
.home-tbox{
	display: flex;
	align-items: center;
	justify-content: space-between;
	.left{
		display: flex;
		align-items: center;
		justify-content: flex-start;
		img{
			width: 32px;
			height: 21px;
		}
		.home-title{
			font-family: PingFangSC, PingFang SC;
			font-weight: 600;
			font-size: 24px;
			color: rgba(0,0,0,0.85);
			line-height: 54px;
			text-align: left;
			font-style: normal;
			margin-left: 16px;
		}
	}
	.right{
		.proj-right{
			font-family: PingFangSC, PingFang SC;
			font-weight: 400;
			font-size: 14px;
			color: rgba(0,0,0,0.45);
			line-height: 14px;
			text-align: left;
			font-style: normal;
		}
	}
}

.project-box{
	width: 100%;
	height: 666px;
	background-image: url('../../static/images/home/news_bg@2x.jpg');
	background-size: 100% 100%;
	margin-top: 64px;
	.project-content{
		width: 100%;
		height: 460px;
		background: #FFFFFF;
		box-shadow: 0px 6px 10px 6px rgba(44,46,51,0.03), 6px 6px 20px 3px rgba(44,46,51,0.06);
		border-radius: 10px;
		margin-top: 24px;
		display: flex;
		.left{
			width: 240px;
			height: 100%;
			border-right: 1px solid #E8E8E8;
			padding-top: 24px;
			.left-item{
				display: flex;
				align-items: center;
				justify-content: flex-start;
				padding: 0 39px 0 24px;
				margin-bottom: 16px;
				width: 181px;
				height: 24px;
				cursor: pointer;
				.left-title{
					font-family: PingFangSC, PingFang SC;
					font-weight: 600;
					font-size: 16px;
					color: rgba(0,0,0,0.85);
					line-height: 24px;
					text-align: left;
					font-style: normal;
				}
				.active-left{
					width: 10px;
					height: 4px;
					background: #4077DD;
					margin-right: 10px;
				}
			}
		}
		.right{
			width: calc(100% - 241px);
			padding: 24px 24px 0;
			.project-item{
				width: calc(100% - 48px);
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 19px;
				.item-left{
					width: 100%;
					display: flex;
					justify-content: flex-start;
					.status-lines{
						.status-btn1{
							padding: 0 6px;
							height: 22px;
							background: #1677FF;
							border-radius: 4px;
							font-family: PingFangSC, PingFang SC;
							font-weight: 500;
							font-size: 10px;
							color: #FFFFFF;
							line-height: 14px;
							text-align: center;
							font-style: normal;
							display: flex;
							justify-content: center;
							align-items: center;
						}
						.status-btn2{
							padding: 0 6px;
							height: 22px;
							background: #FFC726;
							border-radius: 4px;
							font-family: PingFangSC, PingFang SC;
							font-weight: 500;
							font-size: 10px;
							color: #FFFFFF;
							line-height: 14px;
							text-align: center;
							font-style: normal;
							display: flex;
							justify-content: center;
							align-items: center;
						}
						.status-btn3{
							padding: 0 6px;
							height: 22px;
							background: #10C550;
							border-radius: 4px;
							font-family: PingFangSC, PingFang SC;
							font-weight: 500;
							font-size: 10px;
							color: #FFFFFF;
							line-height: 14px;
							text-align: center;
							font-style: normal;
							display: flex;
							justify-content: center;
							align-items: center;
						}
						.status-btn4{
							padding: 0 6px;
							height: 22px;
							background: #E8E8E8;
							border-radius: 4px;
							font-family: PingFangSC, PingFang SC;
							font-weight: 500;
							font-size: 10px;
							color: #FFFFFF;
							line-height: 14px;
							text-align: center;
							font-style: normal;
							display: flex;
							justify-content: center;
							align-items: center;
						}
					}
					.title-lines{
						width: calc(100% - 100px);
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 16px;
						color: rgba(0,0,0,0.85);
						line-height: 21px;
						text-align: left;
						font-style: normal;
						margin-left: 8px;
						cursor: pointer;
					}
				}
				.item-right{
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 14px;
					color: #FF4D4F;
					line-height: 21px;
					text-align: right;
					font-style: normal;
				}
				.itemStatus{
					width: calc(100% - 200px);
				}
			}
		}
	}
}

.app-box{
	width: 100%;
	height: 280px;
	background-image: url('../../static/images/home/appbannerbg@2x.jpg');
	background-size: 100% 100%;
	.app-content{
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 100%;
		.left{
			.app-left-title{
				font-family: PingFangSC, PingFang SC;
				font-weight: 600;
				font-size: 36px;
				color: #FFFFFF;
				line-height: 54px;
				text-align: left;
				font-style: normal;
			}
			.app-left-info{
				font-family: PingFangSC, PingFang SC;
				font-weight: 500;
				font-size: 18px;
				color: #FFFFFF;
				line-height: 54px;
				text-align: left;
				font-style: normal;
			}
		}
		.right{
			display: flex;
			align-items: center;
			.down-box{
				margin-left: 37px;
				img{
					width: 118px;
					height: 118px;
					background: #FFFFFF;
				}
				.down-title{
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 14px;
					color: #FFFFFF;
					line-height: 20px;
					text-align: left;
					font-style: normal;
					margin-top: 10px;
					text-align: center;
				}
			}
		}
	}
}

.trends-box{
	.trends-content {
		display: flex;
		margin-top: 24px;
		.dt_box_left {
			width: 50%;
			.listLines {
				padding: 20px 0;
				.left {
					width: 70px;
				}
				.times {
					color: #666;
				}
				.xians {
					height: 30px;
					border-left: 1px solid #999;
				}
				.contentText {
					color: #757575;
					flex: 1;
					padding-left: 15px;
				}
			}
		}
	
	    .dt_box_right {
			width: 40%;
			margin-left: 7%;
			.listLines2 {
				padding: 30px 0;
				border-top: 1px solid #ddd;
				cursor: pointer;
				.left {
					width: 70px;
				}
				.times {
					color: #666;
				}
						
				.xians {
					height: 30px;
					border-left: 1px solid #999;
				}
						
				.contentText {
					flex: 1;
					padding-left: 15px;
					.titlesLines {
					  font-size: 18px;
					  font-weight: 550;
					}
					.neirong {
					  color: #757575;
					  font-size: 14px;
					}
				}
			}
	    }
	}
}

.about-box{
	.about-content{
		display: flex;
		margin-top: 24px;
		.left{
			.info{
				font-family: PingFangSC, PingFang SC;
				font-weight: 500;
				font-size: 16px;
				color: rgba(0,0,0,0.85);
				line-height: 24px;
				text-align: left;
				font-style: normal;
				margin-bottom: 24px;
			}
			.about-btn-list{
				display: flex;
				justify-content: flex-start;
				align-items: center;
				.about-btn{
					width: 88px;
					height: 32px;
					background: #4077DD;
					border-radius: 6px;
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 14px;
					color: #FFFFFF;
					line-height: 22px;
					text-align: center;
					font-style: normal;
					display: flex;
					justify-content: center;
					align-items: center;
				}
				.about-btn2{
					width: 88px;
					height: 32px;
					background: #FFFFFF;
					border-radius: 8px;
					border: 1px solid rgba(0,0,0,0.15);
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 14px;
					color: rgba(0,0,0,0.88);
					line-height: 24px;
					text-align: left;
					font-style: normal;
					display: flex;
					justify-content: center;
					align-items: center;
					margin-left: 8px;
				}
			}
		}
		.right{
			img{
				width: 567px;
				height: 360px;
			}
		}
	}
}

.advantage-box{
	width: 100%;
	height: 890px;
	background-image: url('../../static/images/home/ys_bg@2x.jpg');
	background-size: 100% 100%;
	margin-top: 64px;
	.advantage-content{
		position: relative;
		display: flex;
		flex-flow: column;
		align-items: center;
		.adv-top{
			font-family: PingFangSC, PingFang SC;
			font-weight: 500;
			font-size: 16px;
			color: rgba(0,0,0,0.85);
			line-height: 24px;
			text-align: left;
			font-style: normal;
			margin-top: 24px;
		}
		.adv-img-box{
			width: 100%;
			height: 100%;
			position: absolute;
			top: 72px;
			display: flex;
			justify-content: center;
			//align-items: center;
			.adv-img{
				width: 666px;
				height: 577px;
			}
		}
		
		.advantage-list{
			width: 100%;
			margin-top: 76px;
			.adv-line1{
				display: flex;
				justify-content: space-between;
				padding: 0 85px 0 73px;
				margin-bottom: 46px;
			}
			.adv-line2{
				display: flex;
				justify-content: space-between;
				margin-bottom: 74px;
			}
			.adv-line3{
				display: flex;
				justify-content: space-between;
				padding: 0 79px 0 30px;
				margin-bottom: 108px;
			}
			.adv-line4{
				display: flex;
				justify-content: space-between;
				padding: 0 173px 0 91px;
			}
			.adv-item{
				position: relative;
				z-index: 9999;
				img{
					width: 41px;
					height: 41px;
					position: absolute;
				}
				.adv-title{
					font-family: PingFangSC, PingFang SC;
					font-weight: 600;
					font-size: 16px;
					color: rgba(0,0,0,0.85);
					line-height: 24px;
					text-align: left;
					font-style: normal;
					position: relative;
					left: 26px;
					top: 10px;
				}
				.adv-info{
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 14px;
					color: rgba(0,0,0,0.45);
					line-height: 24px;
					text-align: left;
					font-style: normal;
					width: 240px;
					position: relative;
					left: 26px;
					top: 10px;
				}
			}
		}
	}
}

.service-box{
	.service-content{
		.service-top{
			font-family: PingFangSC, PingFang SC;
			font-weight: 500;
			font-size: 16px;
			color: rgba(0,0,0,0.85);
			line-height: 32px;
			text-align: left;
			font-style: normal;
			margin-top: 24px;
		}
		.service-list{
		
			.service-item{
				width: 50%;
				
				.service-title{
					font-family: PingFangSC, PingFang SC;
					font-weight: 600;
					font-size: 16px;
					color: #4077DD;
					line-height: 24px;
					text-align: left;
					font-style: normal;
				}
				.service-info{
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 14px;
					color: rgba(0,0,0,0.85);
					line-height: 24px;
					text-align: left;
					font-style: normal;
					margin-top: 9px;
				}
			}
		}
	}
}

.case-box{
	.case-content{
		position: relative;
		margin-top: 28px;
		.el-icon-arrow-left{
			font-size: 20px;
			// color: #666;
			cursor: pointer;
			// &::before{
			// 	position: absolute;
			// 	top: 40%;
			// 	left: 0;
			// 	z-index: 999;
			// 	background-color: #ffffff30;
			// }
		}
		.el-icon-arrow-right{
			font-size: 20px;
			// color: #666;
			cursor: pointer;
			// &::before{
			// 	position: absolute;
			// 	top: 40%;
			// 	right: 0;
			// 	z-index: 999;
			// 	background-color: #ffffff30;
			// }
		}
		.swerpr {
			height: 185px;
			flex: 1;
			
		    .anliBox {
				margin: 0 20px;
				width: 250px;
				border-radius: 4px;
				position: relative;
				overflow: hidden;
				height: 185px;
			
				img {
				  width: 250px;
				  height: 185px;
				  cursor: pointer;
				}
			
				.names {
				  position: absolute;
				  bottom: 0;
				  width: 100%;
				  height: 44px;
				  background: rgba(0, 0, 0, 0.46);
				  font-size: 13px;
				  text-align: center;
				  color: #fff;
				  line-height: 44px;
				}
		    }
		}
	}
}

.links-box{
	.links-content{
		.swerpr2 {
		   height: 110px;
		   flex: 1;
			.anliBox {
				width: 250px;
				border-radius: 3px;
				position: relative;
				overflow: hidden;
				height: 110px;
			
				img {
				  width: 250px;
				  height: 110px;
				  cursor: pointer;
				}
			
				.names {
					position: absolute;
					bottom: 0;
					width: 100%;
					height: 44px;
					background: rgba(0, 0, 0, 0.46);
					font-size: 13px;
					text-align: center;
					color: #fff;
					line-height: 44px;
				}
			}
		}
	}
}

.bottom-box{
	width: 100%;
	height: 230px;
	background-image: url('../../static/images/home/bottom-bannerbg@2x.jpg');
	background-size: 100% 100%;
	margin-top: 64px;
	display: flex;
	justify-content: center;
	align-items: center;
	.btn-fabu{
		cursor: pointer;
		width: 230px;
		height: 70px;
		background: #FFFFFF;
		border-radius: 6px;
		font-family: PingFangSC, PingFang SC;
		font-weight: 500;
		font-size: 18px;
		color: #4077DD;
		line-height: 22px;
		text-align: center;
		font-style: normal;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.home-tip{
	::v-deep .el-dialog{
		width: 700px !important;
		height: 400px;
		background: #FFFFFF;
		box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1), 0px 0px 5px 0px rgba(0,0,0,0.14);
		border-radius: 10px;
		.el-dialog__body{
			padding: 0 32px 0 32px;
		}
		.title{
			font-family: PingFangSC, PingFang SC;
			font-weight: 500;
			font-size: 18px;
			color: rgba(0,0,0,0.85);
			line-height: 36px;
			text-align: left;
			font-style: normal;
		}
		.info{
			font-family: PingFangSC, PingFang SC;
			font-weight: 500;
			font-size: 16px;
			color: rgba(0,0,0,0.85);
			line-height: 36px;
			text-align: left;
			font-style: normal;
		}
		.dialog-footer{
			padding-top: 80px;
		}
	}
}

</style>
