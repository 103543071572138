<template>
  <div class="boxCenter">
	  <div class="topNavs">
	  	<div class="mdx-flex mdx-row-between">
	  		<el-breadcrumb separator-class="el-icon-arrow-right">
	  			<el-breadcrumb-item :to="{ path: '/myPublishProject' }">我发布的项目</el-breadcrumb-item>
	  			<el-breadcrumb-item>项目详情</el-breadcrumb-item>
	  		</el-breadcrumb>
	  	</div>
	  	<div class="topMeg cursor" @click="userTsShow = true">
	  		<img src="../../../static/images/project/zixun@2x.png" />
	  		<span>客户投诉(<span style="margin-left: 0;" v-if="projectInfo.tprojectComplainList!=null">{{projectInfo.tprojectComplainList.totalNum || '0'}}</span><span style="margin-left: 0;" v-else>0</span>)</span>
	  	</div>
	  </div>
	
	<div class="hall-top">
		<div class="topSn">
			<span>
				<span>项目编号：{{ projectInfo.sn || '--' }}</span>
				<span style="margin-left: 24px; color: #4077DD;" v-if="projectInfo.splitProjects!=null && projectInfo.splitProjects.length > 0">关联项目:
					<span v-for="(item,index) in projectInfo.splitProjects" :key="index" style="margin-left: 4px; color: #4077DD;">{{item.sn || ''}}</span>
				</span>
			</span>
			
		</div>
		<div class="top-content">
			<div class="left">
				<div class="topNames">
					<div class="line2 wzk-flex-1" style="display: flex; flex-flow: wrap;">
						<span v-if="projectInfo.status == 1" class="topState on-sign-up">待审核</span>
						<span v-if="projectInfo.status == 2" class="topState on-conduct">待付款</span>
						<span v-if="projectInfo.status == 3" class="topState on-sign-up">报名中</span>
						<span v-if="projectInfo.status == 4" class="topState on-conduct">项目进行中</span>
						<span v-if="projectInfo.status == 5" class="topState on-success">圆满完成</span>
						<span v-if="projectInfo.status == 9" class="topState on-completed">已取消</span>
						
						<!-- <div class="status" v-if="projectInfo.status == 2 && projectInfo.automaticFail == 1">已失效</div>
						<div class="status" v-if="projectInfo.status == 2 && projectInfo.offlineStatus == -1">待付款</div>
						<div class="status blue" v-if="projectInfo.status == 2 && projectInfo.offlineStatus == 1">线下转账审核中</div>
						<div class="status error" v-if="projectInfo.status == 2 && projectInfo.offlineStatus == 3">线下转账审核未通过</div>
						<div class="status blue" v-if="projectInfo.status == 4 && projectInfo.checkStatus == 3">待验收</div> -->
						
						<span>{{ projectInfo.regionFy }}/{{ projectInfo.typeFy }}</span>
						<template v-if="projectInfo.majorFyOne.length">
							<span v-for="(row, index) in projectInfo.majorFyOne" :key="index">/{{ row.name }}</span>
						</template>
						<span>/总投资:￥{{ projectInfo.totalInvestment || '00' }}</span>
						<span>/总规模:{{ projectInfo.totalScale }}m²</span>
						<span>/{{ projectInfo.serviceContentFy }}</span>
						<div style="color: #4077DD;font-size: 14px;margin-left: 8px;" v-if="projectInfo.progressList.length" @click="openJinduShow = true">查看进度</div>
					</div>
					
					
				</div>
				<div class="topTagsBox">
					<span class="tags">{{ projectInfo.regionFy }}</span>
					<span class="tags" v-if="projectInfo.necessary == 1">需要出现场</span>
					<span class="tags" v-if="projectInfo.provided == 1">提供CAD图纸</span>
					<span class="tags">{{ projectInfo.typeFy }}</span>
					<template v-if="projectInfo.majorFy.length">
						<span class="tags" v-for="(row, index) in projectInfo.majorFy" :key="index + 'w'">{{ row.name }}</span>
					</template>
					<span class="tags" v-if="projectInfo.serviceContentFy">{{ projectInfo.serviceContentFy }}</span>
					<span class="tags" v-for="(row, index) in projectInfo.softwareFy" :key="index + 'e'">{{ row.label }}</span>
				</div>
				<div class="topPrice">
					<span class="prices w-m-r-30" v-if="projectInfo.orderPrice">
					  平台价格：{{ projectInfo.orderPrice || '' }}
					</span>
					<span class="prices w-m-l-20" v-if="projectInfo.projectType == 2">
					  固定总价
					</span>
					<span class="prices" v-if="!projectInfo.orderPrice">
					  预估价格：{{ projectInfo.estimatedPrice || '暂无估价' }}
					</span>
				</div>
				<div class="topTime m-flex">
					<span> 平台交付时间：{{ projectInfo.deliveryDeadline }}</span>
					<!-- <span class="w-m-l-30" v-if="projectInfo.status==3 || projectInfo.status==4">工程师成果交付时间：{{ projectInfo.engineerDeadline }}</span> -->
					<span class="yq-time" v-if="projectInfo.tprojectPostpone!=null && projectInfo.tprojectPostpone.dayNum > 0 && projectInfo.tprojectPostpone.postponeType==0">已延期{{projectInfo.tprojectPostpone.dayNum}}天</span>
				</div>
			</div>
			<div class="right">
				<div>
					<div class="right-type-box2" v-if="projectInfo.status ==3 || projectInfo.status ==4">
						<template>
							<el-dropdown @command="handleCommand">
								<el-button size="small" class="right-btn cursor" type="text" @click.stop="">更多</el-button>
								<el-dropdown-menu slot="dropdown" style="width: 120px; left: 0;"> 
									<el-dropdown-item :command="{projectInfo, type: 1, }" v-if="projectInfo.status ==3 || projectInfo.status ==4">补充附件</el-dropdown-item>
									<el-dropdown-item :command="{projectInfo, type: 3, }" v-if="projectInfo.status ==4">申请取消项目</el-dropdown-item>
									<el-dropdown-item :command="{projectInfo, type: 8, }" v-if="projectInfo.status == 1 || (projectInfo.status == 2 && (projectInfo.offlineStatus == -1 || projectInfo.offlineStatus == 3)) || projectInfo.status == 3 && projectInfo.isCancel == 1">取消项目</el-dropdown-item >
									<el-dropdown-item :command="{projectInfo, type: 9, }" v-if="projectInfo.status == 4">投诉项目</el-dropdown-item>
									<el-dropdown-item :command="{projectInfo, type: 10, }" v-if="projectInfo.status == 4">申请延期</el-dropdown-item>
									<el-dropdown-item :command="{projectInfo, type: 11, }" v-if="projectInfo.status == 4 && projectInfo.checkStatus ==3">延期验收</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</template>
						<div class="right-two-box" v-if="projectInfo.status==3">
							<div class="type-btn cursor" @click="openInvites()" v-if="projectInfo.zpEngineer==1">
								<span>邀请工程师</span>
							</div>
							<div class="type-btn" style="background: #ddd; color: rgba(0,0,0,0.45);;" v-else>
								<span>邀请工程师</span>
							</div>
							<div class="type-number">
								<img src="../../../static/images/project/hot@2x.png" />
								<span>{{projectInfo.signUpNum || '0'}} 个工程师已报名</span>
							</div>
						</div>
						<!-- checkStatus 验收状态(-1.作业中（成果未上传），1.项目经理待验收（待审核），2.项目经理验收未通过，3.客户待验收，4.客户验收通过，5.客户验收未通过，6项目经理审核通过未转交客户验收) -->
						<!-- <div class="right-two-box" v-if="projectInfo.status==4 && projectInfo.checkStatus == -1">
							<div class="type-btn cursor" @click="timeShowApply=true">
								<span>申请延期</span>
							</div>
						</div> -->
						<div class="right-two-box" v-else-if="projectInfo.status==4 && projectInfo.checkStatus == 3">
							<div class="type-btn cursor"  @click="questionBackApply=true">
								<span>成果验收</span>
								<img src="../../../static/images/project/type-icon-2@2x.png" class="top-left-tabs"  />
							</div>
							<!-- <div class="type-back w-m-t-20" @click="questionBackShow=true">项目成果存在质量问题，请反馈</div>
							<div class="type-back w-m-t-20" style="color: #FFB420; text-decoration-line: none;">反馈已提交，请等待平台处理</div> -->
						</div>
						<div class="right-two-box" v-else>
							<div class="type-btn" style="background: #ddd; color: rgba(0,0,0,0.45);" >
								<span>成果验收</span>
								<img src="../../../static/images/project/type-icon-3@2x.png" class="top-left-tabs"  />
							</div>
						</div>
					</div>
				</div>
					
				<div v-if="projectInfo.status == 5">
					 <div class="right-rate-box1">
					 	<div class="rate-list">
							<div class="item-rate">
							   <div class="item-title">客户评分：</div>
							   <el-rate v-model="uvalue" disabled disabled-void-color="#D8D8D8"></el-rate>
							</div>
							<div class="item-rate">
								<div class="item-title">项目经理评分：</div>
								<el-rate v-model="mvalue" disabled disabled-void-color="#D8D8D8"></el-rate>
							</div>
							<div class="type-btn cursor" @click.stop="evalutShowtap(1)" v-if="projectInfo.isEvaluateUser==0">
								<span>立即评价</span>
							</div>
						</div>
					 </div>
				 </div>	
				
				 <div class="right-over-box1" v-if="projectInfo.status == 9 && zdMyInfo!=null">
					<!-- <div class="over-item">
						<span>初始服务费：</span>
						<span>{{zdMyInfo.platformFee || '0'}}元</span>
					</div> -->
					<div class="over-item" v-for="(item,index) in zdMyInfo.serviceAccountList" :key="index">
						<span>{{item.takeReason || ''}}：</span>
						<span>{{item.takeAmount || '0.00'}}元</span>
					</div>
					<div class="over-item" style="color: #4077DD; border-top: 1px solid #E8E8E8; margin-top: 8px; padding-top: 12px;">
						<span>实际结算：</span>
						<span>{{zdMyInfo.serviceMoney || '0'}}元</span>
					</div>
					<div class="over-item" style="color: #FF4D4F;">
						<span>应退还：</span>
						<span>{{zdMyInfo.service || '0'}}元</span>
					</div>
				 </div>
				 
				
			</div>
		</div>
		
		
		<div class="topTip" v-if="projectInfo.status==4 && projectInfo.tprojectPostpone!=null && projectInfo.tprojectPostpone.isWho==0 && projectInfo.tprojectPostpone.managerType==2">
			<div class="topTitle">
				<div class="tipLeft">
					<img src="../../../static/images/center/wait@2x.png" />
					<span>您已经申请项目延期，等待项目经理审核</span>
				</div>
			</div>
		</div>
		<div v-if="projectInfo.status==4 && projectInfo.cancelOrder!=null && projectInfo.cancelOrder.type==7 && projectInfo.cancelOrder.status==1">
			 <div class="topTip">
			 	<div class="topTitle">
			 		<div class="tipLeft">
			 			<img src="../../../static/images/center/wait@2x.png" />
			 			<span>您已经申请取消并终止项目，请等待项目经理审核。</span>
			 		</div>
			 	</div>
			 </div>
		</div>
		<div v-if="projectInfo.status==4 && projectInfo.cancelOrder!=null && projectInfo.cancelOrder.type==7 && projectInfo.cancelOrder.status==3">
			 <div class="topTip">
			 	<div class="topTitle">
			 		<div class="tipLeft">
			 			<img src="../../../static/images/center/wait@2x.png" />
			 			<span>拒绝原因：{{projectInfo.cancelOrder.cause || ''}}</span>
			 		</div>
			 	</div>
			 </div>
		</div>
	</div>
	<div class="hall-box">
		<div class="left">
			<div class="lines-title w-m-t-0">
				<span>项目详情</span>
				<div class="hengxian"></div>
			</div>
			<div class="left-box-1">
				<div class="left-title">项目基本信息</div>
				<div class="btLines m-flex">
					<div class="tag-title" style="width: 80px;">项目类型：</div>
					<div style="width: calc(100% - 80px);">
						<el-tag type="info" class="tags">{{ projectInfo.typeFy }}</el-tag>
					</div>
				</div>
				<div class="btLines w-m-t-2 m-flex">
				    <div class="tag-title" style="width: 80px;">所含专业：</div>
					<div style="width: calc(100% - 80px);">
						<el-tag type="info" class="tags" v-for="(item, index) in projectInfo.majorFy" :key="index + 'm'">{{ item.name }}</el-tag>
					</div>
				</div>
				<div class="btLines w-m-t-2 m-flex">
				    <div class="tag-title" style="width: 80px;">服务内容：</div>
					<div style="width: calc(100% - 80px);">
						<el-tag type="info" class="tags">{{ projectInfo.serviceContentFy }}</el-tag>
					</div>
				</div>
				<div class="btLines w-m-t-2 m-flex">
				    <div class="tag-title" style="width: 80px;">要求软件：</div>
					<div style="width: calc(100% - 80px);">
						<el-tag type="info" class="tags" v-for="(item, index) in projectInfo.softwareFy" :key="index + 's'">
							{{ item.label }}
						</el-tag>
					</div>
				</div>
				<!-- <div class="btLines w-m-t-2">
				  <span class="tag-title">总投资：{{ '￥' + projectInfo.totalInvestment }}</span>
				</div> -->
				<div class="btLines w-m-t-16">
				    <span class="tag-title">总规模：{{ projectInfo.totalScale }}m²</span>
				</div>
				<div class="btLines">
				    <span class="tag-title">总占地面积：{{ projectInfo.totalArea }}m²</span>
				</div>
				<div class="left-title">项目描述</div>
				<div class="miaoshu">
				    {{ projectInfo.remark || '无' }}
				</div>
			</div>
			
			<div class="lines-title">
				<span>附件资料</span>
				<div class="hengxian"></div>
			</div>
			<div class="left-box-2" v-if="projectInfo.files.length > 0">
				<div class="left-list mdx-flex mdx-row-around mdx-flex-wrap">
					<div class="item" v-for="(item,index) in projectInfo.files" :key='index'>
						<img src="../../../static/images/project/project@2x.png" />
						<div class="item-title line1">
						  {{ item.name }}
						</div>
						<el-button type="primary" class="downFile" size="mini" @click="viewCad(item)" v-if="item.name.includes('dwg')">查看</el-button>
						<el-button type="primary" plain class="downFile w-m-t-8" size="mini" :loading="item.loadStatus" @click="downAttachmentFile(item, index)">下载资料</el-button>
					</div>
					<template v-if="projectInfo.files % 5 != 0">
					  <div v-for="(item, index) in (5 - (projectInfo.files.length % 5))" :key="index + 'o'"></div>
					</template>
				</div>
			</div>
			<div class="lines-title" v-if="projectInfo.supplyFiles.length > 0">
				<span>补充资料</span>
				<div class="hengxian"></div>
			</div>
			<div class="left-box-2" v-if="projectInfo.supplyFiles.length > 0">
				<div class="left-list mdx-flex mdx-row-around mdx-flex-wrap">
					<div class="item" v-for="(item,index) in projectInfo.supplyFiles" :key='index'>
						<img src="../../../static/images/project/project@2x.png" />
						<div class="item-title line1">{{ item.name }}</div>
						<!-- <el-button type="primary" class="downFile" size="mini" @click="viewCad(item)" v-if="item.name.includes('dwg')">查看</el-button> -->
						<el-button type="primary" plain class="downFile" size="mini" @click="downSupplyFile(item, index)">下载资料</el-button>
					</div>
					<template v-if="projectInfo.supplyFiles % 5 != 0">
					   <div v-for="(item, index) in (5 - (projectInfo.supplyFiles.length % 5))" :key="index + 'p'"> </div>
					</template>
				</div>
			</div>
			<div class="lines-title lines-title3" v-if="projectInfo.status == 3">
				<span class="span1" :class="bmActive==2 ?'active':''" @click="tabBmChange(2)">报名人员</span>
				<span class="span2" :class="bmActive==1 ?'active':''" @click="tabBmChange(1)">邀请列表</span>
				<div class="hengxian"></div>
			</div>
			<div class="left-box-3" v-if="projectInfo.status == 3">
				<div class="item-invites m-flex" @click="toDetails(1)" v-for="(item, index) in bmList" :key='index'>
					<div class="left m-flex">
						<WzkAvatar :isGender="true" :gender="item.gender || 1" :imgSrc="item.headImgUrl" :waryWidth="70" :waryHeight="70" :width="70" :height="70" :borderRadius="35"></WzkAvatar>
						<div class="user-invites">
							<div class="invits-top m-flex">
								<span class="username">{{ $mHelper.nameSlice(2, userInfo, item.name, item.name) }}</span>
								<span class="userTag" v-if="item.invitation==0">客户邀请</span>
								<!-- <span class="userTag" v-if="item.invitation==1">项目经理邀请</span> -->
								<el-popover placement="right" width="300" trigger="hover" v-if="item.invitation==1">
								  <div class="" v-if="item.inviteReason!=null">{{item.inviteReason}}</div>
								  <span slot="reference">
								     <span class="userTag">项目经理邀请</span>
								  </span>
								</el-popover>
								
								<el-popover placement="right" width="300" trigger="hover" v-if="item.is_fm==1">
								  <div class="">该工程师在进行中的项目较多，请谨慎选择</div>
								  <span slot="reference">
								     <span class="userTag" style="color: #FF4D4F; border-color: #FF4D4F;">繁忙</span>
								  </span>
								</el-popover>
								
								<el-rate v-model="item.rateValue" disabled disabled-void-color="#D8D8D8"></el-rate>
							</div>
							<div class="invites-sn">
								<span>工程师编号：{{ item.engineerNo || '' }}</span>
								<!-- <span class="w-m-l-24">工程师电话：{{ item.mobile || '' }}</span> -->
							</div>
							<div class="invites-tags">
								<span>精通{{item.majorFy.length || '0'}}个专业</span>
								<span>精通{{item.softwareFy.length || '0'}}个专业软件</span>
								<span>精通{{item.typeFy.length || '0'}}个项目类型</span>
								<span>擅长{{item.regionFy.length || '0'}}个地区</span>
								<span>{{item.years || '0'}}年工作经验</span>
								<span v-if="item.files!=null">有专业证书</span>
							</div>
							<!-- <div class="invites-fen m-flex">
								<div class="invites_lines">
									<div class="numbers">{{ item.favorable }}</div>
									<div class="text">好评率</div>
								</div>
								<div class="invites_lines">
									<div class="numbers">{{ item.completed || 0 }}</div>
									<div class="text">已完成</div>
								</div>
								<div class="invites_lines">
									<div class="numbers">{{ item.timeliness }}</div>
									<div class="text">及时率</div>
								</div>
								<div class="invites_lines">
									<div class="numbers" v-if="item.excellent">{{item.excellent}}%</div>
									<div class="numbers" v-else>--</div>
									<div class="text">优秀率</div>
								</div>
								<div class="invites_lines">
									<div class="numbers">{{ item.reputation }}</div>
									<div class="text">信誉值</div>
								</div>
							</div> -->
						</div>
					</div>
					<div class="right m-flex" v-if="projectInfo.status==3 && bmActive==2">
						<el-button class="btn-error" size="mini" type="primary" v-if="item.jurisdiction == 1" @click="OpenTakeBack(item, 2)">收回权限</el-button>
						<el-button class="btn-default" size="mini" v-if="item.jurisdiction == 2" @click="OpenTakeBack(item, 1)">开放权限</el-button>
						<!-- <el-button class="btn-default" size="mini" type="primary" v-if="item.jurisdiction == 1" @click="surePerson(item)">确 认</el-button> -->
					</div>
					<div class="right m-flex" v-if="projectInfo.status==3 && bmActive==1">
						<span style="color: #4077DD;" v-if="item.statusApply==1">待确认</span>
						<span style="color: #10C550;" v-if="item.statusApply==2">已接受</span>
						<span style="color: #FF4D4F;" v-if="item.statusApply==9">已拒绝</span>
					</div>
				</div>
			</div>
			<div class="lines-title" v-if="projectInfo.status>=4 && projectInfo.tcheckAuditList.length">
				<span>质控记录</span>
				<div class="hengxian"></div>
			</div>
			<div class="left-box-5" v-if="projectInfo.status>=4 && projectInfo.tcheckAuditList.length">
				<el-table :data="projectInfo.tcheckAuditList" style="width: 100%; margin-top: 24px;" :header-cell-style="{ background: '#FAFAFA',  'text-align': 'left' }" :cell-style="{ 'text-align': 'left' }">
				  <el-table-column prop="title" label="文件名称" width="220px">
					  <template slot-scope="scope">
					     <span style="color: #3B77FF;">{{scope.row.fileName || '客户审核'}}</span>
					  </template>
				  </el-table-column>
				  <el-table-column prop="fileUploadTime" label="上传时间" width="170px"></el-table-column>
				  <el-table-column prop="checkStatus" label="结果" width="100px">
					  <template slot-scope="scope">
					     <span v-if="scope.row.checkStatus==1" style="color: #10C550;">通过</span>
						 <span v-if="scope.row.checkStatus==2" style="color: #FF4D4F;">驳回</span>
					  </template>
				  </el-table-column>
				  <el-table-column prop="checkRemark" label="说明" width="100px">
					  <template>
						 <span style="color: #10C550;">--</span>
					  </template>
				  </el-table-column>
				  <el-table-column prop="fileId" label="下载文件" >
					  <template slot-scope="scope">
						 <span v-if="scope.row.fileId!=null" style="color: #3b77ff; cursor: pointer;" @click="downItemOnleyChengguo2(scope.row)">下载</span>
					  </template>
				  </el-table-column>
				  <el-table-column prop="checkTime" label="审核时间" width="170px" />
				</el-table>
			</div>
			<div class="left-box-7" v-if="projectInfo.engineerInfo.id">
				<div class="lines-title">
					<span>留言板</span>
					<div class="hengxian"></div>
				</div>
				<div class="conTBVox" style="margin-top: 30px;">
					<div class="w-m-t-20">
					  <el-radio v-model="radio" :label="1">所有人可见</el-radio>
					  <el-radio v-model="radio" :label="null">项目经理可见</el-radio>
					</div>
				  <div class="w-m-t-10">
				    <el-input type="textarea" :rows="8" placeholder="请输入内容" v-model.trim="textarea"></el-input>
				  </div>
				  <div class="w-m-t-10 mdx-flex mdx-row-right">
				    <el-button type="primary" style="padding: 10px 40px;" size="mini" @click="saveMsgTap">发布</el-button>
				  </div>
				</div>
				<div class="conTBVox" style="margin-top: 30px;" v-for="(item) in msgList" :key="item">
				  <div class="mdx-flex liuyanList">
				    <WzkAvatar :imgSrc="item.headImgUrl" :borderRadius='50'></WzkAvatar>
				    <div class="w-m-l-10">
				      <div>
				        {{ $mHelper.nameSlice(item.identity, userInfo, item.nickname, item.name) }}
				        <span v-if="item.identity == 1">(需求方)</span>
				        <span v-if="item.identity == 2"> (工程师)</span>
				        <span v-if="item.identity == 3">(项目经理)</span>
				      </div>
				      <div class="w-m-t-5 times">{{ item.createTime }}</div>
				    </div>
				  </div>
				  <div class="w-p-l-60 w-m-t-10">{{ item.content }}</div>
				  <div class="blue w-font-14 mdx-text-right w-m-t-10">
				    <span v-if="item.type == 1">所有人可见</span>
				    <span v-if="item.type == 2">客户可见</span>
				    <span v-if="item.type == 3">工程师可见</span>
					<span v-if="item.type == null">项目经理可见</span>
				  </div>
				</div>
				<div class="mdx-flex mdx-row-center " style="margin-top: 30px;" v-if="msgList.length">
				  <el-pagination :pageSize="queryParams.limit" :currentPage='queryParams.page' background layout="prev, pager, next" @current-change='pageChange' :page-count="queryParams.totalPage || 1"></el-pagination>
				</div>
			</div>
			
		</div>
		<div class="right">
			<div class="right-box-apply" v-if="projectInfo.status==4 && projectInfo.tprojectPostpone!=null && projectInfo.tprojectPostpone.clientType==2 && projectInfo.tprojectPostpone.managerType==0  && projectInfo.tprojectPostpone.isWho==1">
				<div class="apply-title">项目延期申请 </div>
				<div class="apply-info">工程师申请将项目延期原因如下：</div>
				<div class="apply-cont">{{projectInfo.tprojectPostpone.cause || ''}} </div>
				<div class="apply-memver" style="height: 21px;">
					<span v-if="projectInfo.tprojectPostpone.isWho==0"> </span>
					<span style="color: #10C550;" v-else-if="projectInfo.tprojectPostpone.isWho==1 && projectInfo.tprojectPostpone.managerType==0">项目经理已同意</span>
					<span v-else-if="projectInfo.tprojectPostpone.isWho==1 && projectInfo.tprojectPostpone.managerType==1">项目经理不同意</span>
					<span style="color: #3B77FF;" v-else-if="projectInfo.tprojectPostpone.isWho==1 && projectInfo.tprojectPostpone.managerType==2">项目经理待审核</span>
				</div>
				<div class="apply-btn cursor" @click="timeToApply=true">立即处理</div>
			</div>
			
			<div class="right-box-apply" v-if="projectInfo.status==4 && projectInfo.tprojectPostpone!=null && projectInfo.tprojectPostpone.isWho==0  && projectInfo.tprojectPostpone.managerType!=0 ">
				<div class="apply-title">项目延期申请 </div>
				<div class="apply-info">申请项目延期原因如下：</div>
				<div class="apply-cont">{{projectInfo.tprojectPostpone.cause || ''}} </div>
				<div class="apply-info" style="color: #EBBD11;" v-if="projectInfo.tprojectPostpone.managerType == 2">待项目经理审核</div>
				<div class="apply-info" style="color: #FF4D4F; display: flex; align-items: center;" v-if="projectInfo.tprojectPostpone.managerType == 1">
					<img style="width: 20px; height: 20px;" src="../../../static/images/project/toback@2x.png" />
					审核不通过，原因如下：</div>
				<div class="apply-cont" style="margin-bottom: 10px;" v-if="projectInfo.tprojectPostpone.managerType==1">{{projectInfo.tprojectPostpone.managerCase || ''}} </div>
			</div>
			
			
			<div class="right-box-guo" v-if="projectInfo.checkStatus==3 && projectInfo.projectFiles.length">
				<div class="top-guo m-flex">
					<div class="name">成果文件.zip</div>
					<div class="wzk-cursor jieguoBtn" @click="downFruitFile"> 下载文件</div>
				</div>
				<div class="times">
				    上传时间：{{ getLastFileTime(0) }}
				</div>
			    <img src="../../../static/images/project/default@2x.png">
				<div class="title">验收成果</div>
				<!-- <el-button type="primary" plain class="downFile" size="mini" :loading="loadEngiFruit" @click="downloadEngineerFruit">下载资料</el-button> -->
			</div>
			
			<div class="right-box-1" >
				<div class="right-title">发布者</div>
				<div class="right-user">
					<div class="block">
						<el-image class='image-slot' :src="projectInfo.customerInfo.headImgUrl">
							<div slot="error" class="image-slot">
								<img src="../../../static/images/mrtx.png" style="width: 80px;height: 80px;border-radius: 5px;">
							</div>
						</el-image>
					</div>
					<div class="right-uinfo">
						<div class="name">{{ $mHelper.nameSlice(1, userInfo, projectInfo.customerInfo.nickname, projectInfo.customerInfo.name)}}</div>
						<div class="rStatis right-wrz" v-if="projectInfo.customerInfo.certification==2 || projectInfo.customerInfo.certification==null">未认证</div>
						<div class="rStatis right-grrz" v-else-if="projectInfo.customerInfo.certification==1">个人认证</div>
						<div class="rStatis right-qyrz" v-else>企业认证</div>
					</div>
				</div>
				<div class="right-phone">电话： {{  $mHelper.mobileSlice(userInfo.identity, projectInfo.customerInfo.mobile) }}</div>
				<div class="right-phone" v-if="projectInfo.customerInfo.qq">QQ： {{ $mHelper.qqSlice(userInfo.identity, projectInfo.customerInfo.qq) }}</div>
				<div class="right-phone" v-if="projectInfo.customerInfo.mailbox">邮箱： {{ $mHelper.emailSlice(userInfo.identity, projectInfo.customerInfo.mailbox) }}</div>
				<div class="right-time">发布时间：{{projectInfo.createTime}}</div>
			</div>
			<div class="right-box-1 right-box-2" v-if="projectInfo.memberInfo !=null && projectInfo.memberInfo.id !=null">
				<div class="right-title">项目经理</div>
				<div class="right-user">
					<div class="block">
						<el-image class='image-slot' :src="projectInfo.memberInfo.headImgUrl">
							<div slot="error" class="image-slot">
								<img src="../../../static/images/mrtx.png" style="width: 80px;height: 80px;border-radius: 5px;">
							</div>
						</el-image>
					</div>
					<div class="right-uinfo">
						<div class="name">{{ $mHelper.nameSlice(3, userInfo, projectInfo.memberInfo.nickname,projectInfo.memberInfo.name)}}</div>
					</div>
				</div>
				<div class="right-phone">电话： {{ projectInfo.memberInfo.mobile || '' }}</div>
				<div class="right-phone" v-if="projectInfo.memberInfo.qq">QQ： {{ projectInfo.memberInfo.qq || '' }}</div>
				<div class="right-phone" v-if="projectInfo.memberInfo.mailbox">邮箱： {{ projectInfo.memberInfo.mailbox || '' }}</div>
			</div>
			<div class="right-box-1 right-box-2" v-if="projectInfo.engineerInfo !=null && projectInfo.engineerInfo.id !=null">
				<div class="right-title">工程师</div>
				<div class="right-user">
					<div class="block">
						<el-image class='image-slot' :src="projectInfo.engineerInfo.headImgUrl">
							<div slot="error" class="image-slot">
								<img src="../../../static/images/mrtx.png" style="width: 80px;height: 80px;border-radius: 5px;">
							</div>
						</el-image>
					</div>
					<div class="right-uinfo">
						<div class="name">{{ $mHelper.nameSlice(2, userInfo, projectInfo.engineerInfo.nickname, projectInfo.engineerInfo.name) }}</div>
					</div>
				</div>
				
				<div class="right-phone" v-if="projectInfo.depositAmount > 0">质保金： <span style="color: #10C550;">已缴纳（{{ projectInfo.depositAmount || '0.00' }}元）</span></div>
				<div class="right-phone">电话： {{ $mHelper.mobileSlice(userInfo.identity, projectInfo.engineerInfo.mobile) }}</div>
				<div class="right-phone" v-if="projectInfo.engineerInfo.qq">QQ： {{ $mHelper.qqSlice(userInfo.identity, projectInfo.engineerInfo.qq) }}</div>
				<div class="right-phone" v-if="projectInfo.engineerInfo.mailbox">邮箱： {{ $mHelper.emailSlice(userInfo.identity, projectInfo.engineerInfo.mailbox) }}</div>
			</div>
			
		</div>	
	</div>
	  

	
	<!--邀请工程师-->
	<el-dialog class="tipPopup" title="邀请工程师申请此项目"  :visible.sync="inviteShow" show-cancel-button :before-close='handleClose' width="30%" center top='20vh'>
	  <div class="puopContext  w-m-t-30  w-font-16">
	    <div class="mdx-flex">
	      <div class="">工程师编号</div>
	      <div class="wzk-flex-1 w-m-l-20">
	        <el-input style="margin-top: 0;" type="text" placeholder="请输入工程师编号" v-model.trim="engineerNo"></el-input>
	      </div>
	    </div>
		<div class="dialog-bom mdx-flex mdx-row-center w-m-t-46">
			<el-button class="close" size="small" @click="inviteShow = false">取 消</el-button>
		    <el-button type="primary" size="small" :loading="loading" @click.stop="inviteTap()">邀请</el-button>
		</div>
	  </div>
	</el-dialog>
	
	<!--补充附件-->
	<el-dialog class="tipPopup" title="补充附件" :visible.sync="supplementShow" show-cancel-button width="40%" center top="20vh">
      <div class="puopContext w-font-13">
        <div style="font-family: PingFangSC, PingFang SC; font-weight: 400; font-size: 18px; color: rgba(0,0,0,0.85); line-height: 36px; margin-bottom: 20px;">
          {{ projectInfo.regionFy }}/{{ projectInfo.typeFy }}
          <!-- {{projectInfo.majorFy}} -->
          <template v-if="projectInfo.majorFy.length">
            <span v-for="(row, indexss) in projectInfo.majorFy" :key="indexss">/{{ row.name }}</span>
          </template>
          <span> /总投资:￥{{ projectInfo.totalInvestment || "00" }} </span>
          <span> /总规模:{{ projectInfo.totalScale }}m² </span>
          /{{ projectInfo.serviceContentFy }}
        </div>
        <div class="w-m-t-10">
          <el-upload
            class="upload-demo"
            ref="files"
            :action="action"
            :on-success="fujianSuccess"
            :on-progress="onProgress"
            :on-error="onError"
            :on-remove="fujianRemove"
            multiple
            :limit="9"
            :on-exceed="fujianExceed"
            :file-list="fileList"
          >
            <el-button size="small" type="primary">上传文件</el-button>
            <span v-if="onPrepareFile" class="prepare-file">
              文件准备中，请稍后… 文件名末尾显示
              <i class="tip-success el-icon-circle-check"></i>
              才算上传成功
            </span>
          </el-upload>
        </div>
        <div class="mdx-flex mdx-row-center w-m-t-30">
          <el-button type="primary" style="padding: 10px 40px; border-radius: 2px" class="" :loading='loading' @click.stop="suppleUpdata()"> 提交</el-button>
        </div>
      </div>
    </el-dialog>
	
	<!--项目投诉 -->
	<el-dialog class="tipPopup" title="投诉" :visible.sync="complaint" show-cancel-button :before-close="handleComplaint" width="55%" center>
	  <div class="w-font-14 error w-m-t-50">
	    投诉前建议您先与该项目的项目经理沟通，如不能解决您的问题，再选择此渠道。投诉后平台将在3个工作日内与您联系
	  </div>
	  <div class="w-m-t-20">
	    <el-form label-position="left" label-width="100px" :model="formLabelAlign" :rules="rules" ref="fromref">
	      <el-form-item label="项目编号" prop="sn">
	        <div>{{ formLabelAlign.sn }}</div>
	      </el-form-item>
	      <el-form-item label="项目名称" prop="name">
	        <div>{{ formLabelAlign.name }}</div>
	      </el-form-item>
	      <el-form-item label="投诉原因" prop="check">
	        <el-checkbox-group v-model="formLabelAlign.check">
	          <div v-for="(city, index) in cities" :label="city" :key="city">
	            <el-checkbox :label="index + 1" @change="checkbox(index + 1)">{{ city }}</el-checkbox>
	          </div>
	        </el-checkbox-group>
	      </el-form-item>
	      <el-form-item label="投诉说明" prop="remark">
	        <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 6 }" maxlength="255" placeholder="请详细描述您要投诉的原因，方便平台快速处理您的问题" v-model="formLabelAlign.remark" ></el-input>
	      </el-form-item>
	      <el-form-item label="联系方式" prop="contactMethod">
	        <el-input v-model="formLabelAlign.contactMethod" placeholder="请输入联系电话"></el-input>
	      </el-form-item>
	      <span slot="footer" class="dialog-footer">
	        <el-button type="primary" style="padding: 10px 40px; border-radius: 2px" @click="subimtBtn">
	          提交
	        </el-button>
	        <el-button style="padding: 10px 40px; border-radius: 2px; margin-left: 60px" @click="complaint = false">
	          取消
	        </el-button>
	      </span>
	      <el-form-item label="" style="margin-left: 0 !important" class="box">
	        <div class="mdx-text-center">
	          <el-button type="primary" style="padding: 10px 30px" @click="subimtBtn" >提交</el-button >
	          <el-button class="w-m-l-30" style="padding: 10px 30px" @click="handleComplaint" >取消</el-button >
	        </div>
	      </el-form-item>
	    </el-form>
	  </div>
	</el-dialog>
	<!--投诉成功 -->
	<el-dialog  title="提示" :visible.sync="commSucces" :modal="false" width="30%" top="40vh" center :show-close="false"  @click.stop.native="commSucces = true" :close-on-click-modal="false" >
	  <div style=" text-align: center; color: #333333ff; font-size: 16px; margin-top: 20px; " >
	    您已投诉成功！
	  </div>
	  <span slot="footer" class="dialog-footer">
	    <el-button plain @click.stop="commSucces = false" >确认</el-button>
	  </span>
	</el-dialog>
	
	<!--投诉详情-->
	<el-dialog title="投诉详情" :visible.sync="userTsShow"  width="30%" center top='20vh' class="tipPopup ts-dbox" v-if="projectInfo.tprojectComplainList!=null">
	  <div class="" >
		   <div class="ts-title">客户于{{projectInfo.tprojectComplainList.createTime || ''}}进行投诉</div>
		   <div class="ts-info">投诉原因</div>
		   <div class="ts-content">
			   <span v-if="projectInfo.tprojectComplainList.reason==1">工作成果质量存在问题</span>
			   <span v-if="projectInfo.tprojectComplainList.reason==2">项目交付时间延误</span>
			   <span v-if="projectInfo.tprojectComplainList.reason==3">造价师服务态度不好</span>
			   <span v-if="projectInfo.tprojectComplainList.reason==4">造价师个人经验及信息与实际不符</span>
			   <span v-if="projectInfo.tprojectComplainList.reason==5">其他问题</span>
		   </div>
		   <div class="ts-info">投诉说明</div>
		   <div class="ts-content">{{projectInfo.tprojectComplainList.remark || ''}}</div>
	  </div>
	</el-dialog>
	
	<!--延期申请-->
	<el-dialog class="tipPopup" title="延期申请" :visible.sync="timeShowApply" width="30%" center top='20vh'>
	  <div class="puopContext">
		  <el-form ref="form" :model="formYanApplyData" label-width="80px">
			   <el-form-item label="延长截止:" prop="postponeTime">
				 <div class="inputRight">
				   <el-date-picker v-model="formYanApplyData.postponeTime"  type="datetime" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间" @change="estimateMoney()"></el-date-picker>
				 </div>
			   </el-form-item>
			   
			   <el-form-item label="延期原因:" prop="cause">
					<el-input v-model="formYanApplyData.cause" placeholder="请写明延期原因" type="textarea" maxlength="255" :autosize="{ minRows: 4, maxRows: 6 }" class="input"></el-input>
			   </el-form-item>
		  </el-form>
		  <span slot="footer" class="dialog-bom mdx-flex mdx-row-center w-m-t-50">
			<el-button class="close" size="small" @click.stop="timeShowApply=false">取 消</el-button>
		    <el-button class="w-m-l-24" type="primary" size="small" :loading="loading" @click.stop="submitYanEvaluate">确 定</el-button>
		  </span>
	  </div>
	</el-dialog>
	<!--延期审核:picker-options="pickerOptions"-->
	<el-dialog class="tipPopup" title="延期审核" :visible.sync="timeToApply" width="30%" center top='20vh'>
	  <div class="puopContext">
		  <el-form ref="form" :model="formsYanqiApplyData" label-width="80px">
			   <el-form-item label="延长截止:" prop="postponeTime">
				 <div class="inputRight">
					 {{formsYanqiApplyData.postponeTime || ''}}
				   <!-- <el-date-picker v-model="formsYanqiApplyData.postponeTime" disabled  type="datetime" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间" @change="estimateMoney()"></el-date-picker> -->
				 </div>
			   </el-form-item>
			   <el-form-item label="审核结果:">
				 <div class="mdx-flex">
					 <div>
						 <el-radio v-model="formsYanqiApplyData.type" :label="1">通过</el-radio>
						 <el-radio v-model="formsYanqiApplyData.type" :label="2">不通过</el-radio>
					 </div>
				 </div>
			   </el-form-item>
			   <el-form-item label="审核说明:" prop="cause" v-if="formsYanqiApplyData.type==2">
					<el-input v-model="formsYanqiApplyData.managerCase" placeholder="请写明不通过的原因" maxlength="255" type="textarea" :autosize="{ minRows: 4, maxRows: 6 }" class="input"></el-input>
			   </el-form-item>
		  </el-form>
		  <span slot="footer" class="dialog-bom mdx-flex mdx-row-center w-m-t-50">
			<el-button class="close" size="small" @click.stop="timeToApply=false">取 消</el-button>
		    <el-button class="w-m-l-24" type="primary" size="small" :loading="loading" @click.stop="submitYanApplyEvaluate">确 定</el-button>
		  </span>
	  </div>
	</el-dialog>
	<!--项目进展-->
	<el-dialog class="tipPopup" title="项目进展" :visible.sync="openJinduShow" width="30%" center top='20vh'>
	  <div class="">
		  <div class="project-jz" v-if="projectInfo.progressList.length">
		    <div class="wzk-flex-1">
		      <div class="jz-list">
		        <div class="item" v-for="(item, index) in projectInfo.progressList" :key='index'>
		  		  <div class="title ellipsis" >
		  			    <span>{{ item.title }}</span>
		  				<el-tooltip :content="item.content" placement="top" effect="light" popper-class="tooltip-class">
		  				  <div style="color: #4077DD;cursor: pointer; margin-left: 16px;" v-if="item.content">详情</div>
		  				</el-tooltip>
		  		  </div>
		  		  <div class="times">
		  			  {{ item.createTime }}
		  		  </div>
		        </div>
		      </div>
		    </div>
		  </div>
	  </div>
	</el-dialog>
	
	<!--质控审核-->
	<el-dialog class="tipPopup" title="质控审核" :visible.sync="questionBackApply" width="30%" center top='20vh'>
	  <div class="">
		  <div class="title w-m-b-30" style="color: rgba(0,0,0,0.85);">项目经理已审核通过验收成果，待您进行二次确认验收。请先<span class="cursor" style="color: #4077DD;" @click.stop="downWenjianCg">下载成果文件</span>  进行查看，如果您确认验收成果文件，则视为您已经认可了工程师的工作成果，项目将正式结束并进入质保期。</div>
		  <!-- <el-form ref="form" label-width="80px">
			   <el-form-item label="验收结果:">
				 <div class="mdx-flex">
					 <div>
						 <el-radio v-model="rejectType" :label="1">通过</el-radio>
						 <el-radio v-model="rejectType" :label="2">不通过</el-radio>
					 </div>
				 </div>
			   </el-form-item>
			   <el-form-item label="驳回原因:" prop="rejectContent" v-if="rejectType==2">
					<el-input v-model="rejectContent" placeholder="如若不通过，请在此说明理由" type="textarea" maxlength="255" :autosize="{ minRows: 4, maxRows: 6 }" class="input"></el-input>
			   </el-form-item>
		  </el-form> -->
		  <span slot="footer" class="dialog-bom mdx-flex mdx-row-center w-m-t-50">
		  			<el-button class="close" size="small" @click.stop="questionBackApply=false">取 消</el-button>
		    <el-button class="w-m-l-24" type="primary" size="small" :loading="loading"  @click.stop="accepBtns">确 定</el-button>
		  </span>
	  </div>
	</el-dialog>
	
	<!--项目评价-->
	<el-dialog class="pingCss"  title="项目评价" :visible.sync="evalutShow" show-cancel-button  width="40%" center top="20vh">
	  <div class="w-font-13">
		<div class="m-flex">
		   <div class="ping-info">服务态度：</div>
		   <el-rate v-model="star1" :disabled="evalutType == 2"></el-rate>
		</div>
	    <div class="w-m-t-12">
	      <el-input  type="textarea"  placeholder="请输入" :disabled="evalutType == 2" v-model.trim="starText1" :autosize="{ minRows: 3, maxRows: 3 }" maxlength="255" show-word-limit />
	    </div>
		<div class="m-flex w-m-t-24">
		   <div class="ping-info">成果质量：</div>
		    <el-rate v-model="star2" :disabled="evalutType == 2"></el-rate>
		</div>
		<div class="w-m-t-12">
		  <el-input  type="textarea"  placeholder="请输入" :disabled="evalutType == 2" v-model.trim="starText2" :autosize="{ minRows: 3, maxRows: 3 }" maxlength="255" show-word-limit />
		</div>
	    <div class="mdx-flex mdx-row-center w-m-t-30 w-m-b-30" v-if="evalutType == 1">
	      <el-button type="primary" :loading="loading" style="padding: 10px 40px; border-radius: 2px" class="" @click.stop="CommontUpdata()" >
	        提交
	      </el-button>
	    </div>
	  </div>
	</el-dialog>
	
	<!--质量反馈-->
	<!-- <el-dialog class="tipPopup" title="质量反馈" :visible.sync="questionBackShow" width="30%" center top='20vh'>
	  <div class="">
		  <div class="title w-m-b-17" style="color: rgba(0,0,0,0.85);">若您认为项目经理转交的质量成果文件存在质量问题，请在下方进行详细说明；经平台查证后确实存在问题，平台将会按照规则对项目经理进行惩处。请如实说明情况！</div>
		  <el-form ref="form">
			   <el-form-item label="" prop="notes">
					<el-input v-model="formApplyData.notes" placeholder="请详细进行阐述" type="textarea" :autosize="{ minRows: 4, maxRows: 6 }" class="input"></el-input>
			   </el-form-item>
		  </el-form>
		  <span slot="footer" class="dialog-bom mdx-flex mdx-row-center w-m-t-50">
			<el-button class="close" size="small" @click.stop="cancle">暂不反馈</el-button>
		    <el-button class="w-m-l-24" type="primary" size="small" @click.stop="submitEvaluate">提交反馈</el-button>
		  </span>
	  </div>
	</el-dialog> -->
	<!--接单人员确认弹框-->
	<!-- <el-dialog class="tipPopup" title="提示" :visible.sync="openSureShow" width="25%" center top='20vh'>
	  <div class="">
		  <div class="title w-m-b-17" style="color: rgba(0,0,0,0.85);">确认 选择工程师 xxxx 承接项目</div>
		  <span slot="footer" class="dialog-bom mdx-flex mdx-row-center w-m-t-50">
			<el-button class="close" size="small" @click.stop="questionBackShow=false">取消</el-button>
		    <el-button class="w-m-l-24" type="primary" size="small" @click.stop="onSumbitSure" :disabled="isDisable">确认</el-button>
		  </span>
	  </div>
	</el-dialog> -->
	
	<!--申请结束项目-->
	<el-dialog title="申请取消项目" :visible.sync="cancalgxmCloseShow"  width="40%" center top='20vh' class="tipPopup look-box">
	  <div class="w-m-t-16">
		   <el-form ref="form">
			  <div class="title w-m-t-12>" style="color: rgba(0,0,0,0.85);">工程师已开始作业，需项目经理评估项目实际进度确认通过后，平台根据项目经理评估结果将剩余服务费退还至您的账户！ </div>
		   </el-form>
		  <span slot="footer" class="dialog-bom mdx-flex mdx-row-center w-m-t-50">
			<el-button class="close" size="small" @click.stop="cancalgxmCloseShow=false">取 消</el-button>
		    <el-button class="w-m-l-24" type="primary" size="small" @click.stop="submitCacnlEvaluate">确 定</el-button>
		  </span>
	  </div>
	</el-dialog>
	
	<!--邀请弹框-->
	<el-dialog class="" title="邀请工程师" :visible.sync="invitesBoxShow" width="30%" center top="20vh">
		<div style="border: 1px solid #4077DD;width: 70%; height: 60px; cursor: pointer; border-radius: 4px; font-family: PingFangSC, PingFang SC;font-weight: 400; font-size: 16px; color: #4077dd; display: flex; align-items: center; justify-content: center; margin: 20px auto;"  @click="goToInvites()">
			 到人才库邀请
		</div>
		<div class="dialog-bom mdx-flex mdx-row-center w-m-t-35">
			<el-button size="small" class="close" style="width: 120px; height: 40px;" @click="invitation()">通过工程师ID邀</el-button>
			<el-button size="small" class="close" style="width: 120px; height: 40px; margin-left: 30px;" @click="openInviresList()">查看已邀请</el-button>
		</div>
	</el-dialog>
	
	<!--邀请列表-->
	<el-dialog class="tipPopup" title="已邀请列表" :visible.sync="invitesListShow" show-cancel-button width="750px" center top="20vh">
	    <div class="left-box-invites">
	    	<div class="item-invites m-flex" v-for="(item, index) in bmInvitesList" :key='index'>
	    		<div class="left m-flex">
	    			<WzkAvatar :isGender="true" :gender="item.gender || 1" :imgSrc="item.headImgUrl" :waryWidth="70" :waryHeight="70" :width="70" :height="70" :borderRadius="35"></WzkAvatar>
	    			<div class="user-invites">
	    				<div class="invits-top m-flex">
	    					<span class="username">{{ $mHelper.nameSlice(2, userInfo, item.name, item.name) }}</span>
	    					<span class="userTag" v-if="item.invitation==0">客户邀请</span>
	    					<el-popover placement="right" width="300" trigger="hover" v-if="item.invitation==1">
	    					  <div class="" v-if="item.inviteReason!=null">{{item.inviteReason}}</div>
	    					  <span slot="reference">
	    					     <span class="userTag">项目经理邀请</span>
	    					  </span>
	    					</el-popover>
	    					<el-popover placement="right" width="300" trigger="hover" v-if="item.is_fm==1">
	    					  <div class="">该工程师在进行中的项目较多，请谨慎选择</div>
	    					  <span slot="reference">
	    					     <span class="userTag" style="color: #FF4D4F; border-color: #FF4D4F;">繁忙</span>
	    					  </span>
	    					</el-popover>
	    					<el-rate v-model="item.rateValue" disabled disabled-void-color="#D8D8D8"></el-rate>
	    				</div>
	    				<div class="invites-sn">
	    					<span>工程师编号：{{ item.engineerNo || '' }}</span>
	    					<!-- <span class="w-m-l-24">工程师电话：{{ item.mobile || '' }}</span> -->
	    				</div>
	    				<div class="invites-tags">
	    					<span>精通{{item.majorFy.length || '0'}}个专业</span>
	    					<span>精通{{item.softwareFy.length || '0'}}个专业软件</span>
	    					<span>精通{{item.typeFy.length || '0'}}个项目类型</span>
	    					<span>擅长{{item.regionFy.length || '0'}}个地区</span>
	    					<span>{{item.years || '0'}}年工作经验</span>
	    					<span v-if="item.files!=null">有专业证书</span>
	    				</div>
	    			</div>
	    		</div>
	    		<div class="right m-flex" >
	    			<span style="color: #4077DD;" v-if="item.statusApply==1">待确认</span>
	    			<span style="color: #10C550;" v-if="item.statusApply==2">已接受</span>
	    			<span style="color: #FF4D4F;" v-if="item.statusApply==9">已拒绝</span>
	    		</div>
	    	</div>
	    </div>
	</el-dialog>
	
    <el-dialog
      v-if="cadShow"
      :visible.sync="cadShow"
      width="80%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="() => cadShow = false">
      <div id='map-container' v-loading="cadLoading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(255, 255, 255, 0.8)">
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="cadShow = false">确 定</el-button>
      </span> -->
    </el-dialog>
  </div>
</template>

<script>
import WzkAvatar from '../../../components/wzk-Avatar/index.vue';
import { initNumberValue } from "@/common/utils/num-validate.js"
import { isArrObj } from "@/common/utils/common.js"
import vjmap from 'vjmap'; 
import 'vjmap/dist/vjmap.min.css' // 样式一定要引用！！！
import rulesData from "../../../api/rules.js";
export default {
  components: {
    WzkAvatar
  },
  data() {
    return {
		 radio: 1,
      imgSrc: 'https://img2.baidu.com/it/u=1953922700,4041156140&fm=26&fmt=auto',
      titleShow: false,
      puopShow: false,
      textarea: '',
      Id: null,
      projectInfo: {
        majorFyOne: [],
        majorFy: [],
        files: [],
        projectFiles: [],
        supplyFiles: [],
        engineerInfo: {
          id: ""
        },
        memberInfo: {
          name: ''
        }
      },
      bmList: [],
      bmActive: 2,
      msgList: [],
      queryParams: {
        page: 1, //当前页数
        limit: 10, //每页条数
        totalPage: 1
      },
      cadShow:false,
      cadLoading:false,
	  
	  inviteShow: false,	//邀请工程师
	  engineerNo: '',
	  
	  complaint: false, //项目投诉
	  commSucces: false, //成功投诉
	  formLabelAlign: {
	    check: [],
	    contactMethod: "", //联系方式
	    reason: "", //投诉原因（1.我还未确认验收，对工作成果不满意，2.项目已结束，但我对工作成果不满意，3.投诉延误，4.服务态度，5.其他）
	    remark: "",
	    sn: "",
	    name: "",
	  }, 
	  rules: rulesData.complaints,
	  cities: [
	    "工作成果质量存在问题",
	    "项目交付时间延误",
	    "造价师服务态度不好",
	    "造价师个人经验及信息与实际不符",
	    "其他问题",
	  ],
	  userTsShow: false,
		timeShowApply: false, //申请延期
		timeToApply: false, //	延期审核
		formApplyData: {
			times: '',
			notes:'',
			status: 2,
		},
		openJinduShow: false, //查看项目进度
		questionBackShow: false, 	//反馈弹出
		questionBackApply: false,	//质控审核
		rejectType:'', //验收是否通过
		rejectContent: "", //驳回原因
		   
		openSureShow: false,	//确认人员
		supplementShow: false, //上传附件弹窗
		
		files: [], //附件文件
		action: this.$store.state.baseURL + "upload/uploadCos",
		fileList: [],
		onPrepareFile: false,  
		loading: false,   
		formYanApplyData: {
			postponeTime: '',
			cause:'',
		},
		formsYanqiApplyData: {
			postponeTime: '',
			managerCase:'',
			type:'',
		},
		evalutShow: false, //评价弹窗
		evalutType: 1, //评价状态
		star1: 0,
		star2: 0,
		starText1: "",
		starText2: "",
		mvalue: '',
		uvalue: '',
		zdMyInfo: null,
		cancalgxmCloseShow:false, //结束项目申请
	    invitesBoxShow: false,  //邀请方式弹框
		invitesListShow: false, //邀请列表弹框
		bmInvitesList: [],
    }
  },
  computed: {
    userInfo() {
      return this.$store.getters['userInfo']
    }
  },
  created() {
    this.Id = this.$route.query.id || null
	
	this.mvalue = this.$route.query.mrate || null
	this.uvalue = this.$route.query.urate || null
	
    if (!this.Id) {
      this.$message.error('参数错误')
      setTimeout(function () {
        this.$mHelper.to('/myPublishProject')
      }, 1000);
      return
    }
    this.getDetail();
	this.getZhangdan();
  },
  methods: {
    async viewCad(item){
        this.cadShow = true;
        this.cadLoading = true;
        let splitArr = item.url.split('.');
        let splitArr2 = (splitArr[splitArr.length-2]).split('/');
        let filterMapid = splitArr2[splitArr2.length-1];
        // --通过网络路径打开地图--传入DWG路径的http路径打开DWG图
        // js代码
        // 新建地图服务对象，传入服务地址和token
        const env = {
            serviceUrl: "https://vjmap.com/server/api/v1",
            accessToken: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJJRCI6MiwiVXNlcm5hbWUiOiJhZG1pbjEiLCJOaWNrTmFtZSI6ImFkbWluMSIsIkF1dGhvcml0eUlkIjoiYWRtaW4iLCJCdWZmZXJUaW1lIjo4NjQwMCwiZXhwIjoxOTQyMzg5NTc0LCJpc3MiOiJ2am1hcCIsIm5iZiI6MTYyNzAyODU3NH0.VQchVXxgjl5aCp3j3Uf5U2Mpk1NJNirH62Ys-8XOfnY",
            exampleMapId: "sys_zp"
        };
        let svc = new vjmap.Service(env.serviceUrl, env.accessToken)
        // 打开地图
        // 地图ID (如果换成了您自定义的url地址，记得修改此名称id，改成您想要的唯一的名称即可)
        const mapid = filterMapid;
        // 地图url地址，注意：url地址后四个字母必须为.dwg。如果实际中的地址四4位不是.dwg可以随便加个参数如 url?param=xxx&filetype=.dwg
        // const httpDwgUrl = "http://o2o-web.oss-cn-beijing.aliyuncs.com/Sample.dwg" ;
        // https://gczjzb-1308562443.cos.ap-nanjing.myqcloud.com/file/30481654501597257.dwg
        const httpDwgUrl = item.url;
        let res = await svc.openMap({
            // (第一次打开此地图ID时，会根据fileid去获取dwg路径打开，之后会读取缓存打开)
            mapid: mapid, // 地图ID
            fileid: httpDwgUrl,
            mapopenway: vjmap.MapOpenWay.GeomRender, // 以几何数据渲染方式打开
            style: vjmap.openMapDarkStyle() // div为深色背景颜色时，这里也传深色背景样式
        })
        
        /*
        “(第一次打开此地图ID时，会根据fileid去获取dwg路径打开，之后会读取缓存打开)” 的意思是
          举例来说，您第一次打开的图的时候用的是
        {
        mapid: 'map1',
        fileid: 'http://127.0.0.1/1.dwg'
        }
        这时候因为后台没有 map1 这个图纸，所以第一次时 map1 就会去下载 'http://127.0.0.1/1.dwg'
        打开图。
        但是，您第二次的时候如果要换成另外的图2.dwg，但是如果还是用同样的mapid的话，如
        {
        mapid: 'map1',
        fileid: 'http://127.0.0.1/2.dwg'
        }
        这时候为了性能，后台不会再去下载2.dwg了，就直接用之前的下载并处理好的图的缓存数据了。
        这时候有两个选择。
        (1) 要么是updateMap去更新图。
        (2) 要么就重新命名一个新的 mapid 如map2
        */
        if (res.error) {
            message.error(res.error)
        }
        // 获取地图的范围
        let mapExtent = vjmap.GeoBounds.fromString(res.bounds);
        // 建立坐标系
        let prj = new vjmap.GeoProjection(mapExtent);
        console.log('res',res);
        // // 新建地图对象
        // console.log('map',new vjmap())
        let map = new vjmap.Map({
            container: 'map-container', // container ID
            style: svc.rasterStyle(), // 样式，这里是栅格样式,// 矢量瓦片样式
            center: prj.toLngLat(mapExtent.center()), // 中心点
            zoom: 2,
            renderWorldCopies: false
        });
       
        // 地图关联服务对象和坐标系
        map.attach(svc, prj);
        // 使地图全部可见
        map.fitMapBounds();
        await map.onLoad();
        this.cadLoading = false;
    },
    //获取详情
    getDetail() {
      this.$http.get('project/detail', {
        id: this.Id
      }).then(res => {
        if (res.code == 200) {
			// if(res.data.customerInfo.id != this.userInfo.id){
			// 	this.$mHelper.to('/'); 
			// }
          res.data.totalInvestment = initNumberValue(res.data.totalInvestment)
          res.data.totalScale = initNumberValue(res.data.totalScale)
          res.data.totalArea = initNumberValue(res.data.totalArea)
          res.data.estimatedPrice = initNumberValue(res.data.estimatedPrice)
          res.data.orderPrice = initNumberValue(res.data.orderPrice)
          if (res.data.files && isArrObj(res.data.files)) {
            res.data.files.forEach(item => {
              item.loadStatus = false
            });
          }
          if (res.data.supplyFiles && isArrObj(res.data.supplyFiles)) {
            res.data.supplyFiles.forEach(item => {
              item.loadStatus = false
            });
          }
		  
		  
		  if(res.data.tcheckAuditList.length > 0){
			  let datzka = [];
			 res.data.tcheckAuditList.forEach(item => {
			    if(item.checkStatus==1){
					datzka.push(item);
				}
			 }); 
			 
			 res.data.tcheckAuditList = datzka;
			 
		  }
		  
          this.projectInfo = res.data;
		   this.bmListGet();
		     this.getMesList();
		  this.formsYanqiApplyData.postponeTime = res.data.tprojectPostpone.postponeTime;
		 
        
        }
      })
    },
	
	getZhangdan(){
		this.$http.post("project/cancelProject", {
			projectId: this.Id,
		}).then((res) => {
		    if (res.code == 200) {
		         this.zdMyInfo = res.data;
		    } else {
		    }
		  }).catch((err) => {
		    
		  });
		
	},
	
	getLastFileTime(type) {
	  var _time = "";
	  try {
	    if (type==1) {
	      var _index = this.projectInfo.engineerInfo.engineerFiles.length - 1;
	      if (this.projectInfo.engineerInfo.engineerFiles[_index]) {
	        _time = this.projectInfo.engineerInfo.engineerFiles[_index].createDate;
	      }
	    } else {
	      var _index = this.projectInfo.projectFiles.length - 1;
	      if (this.projectInfo.projectFiles[_index]) {
	        _time = this.projectInfo.projectFiles[_index].createDate;
	      }
	    }
	  } catch (error) {}
	  return _time;
	},
    saveMsgTap() {
      if (!this.textarea) {
        this.$message.error('请输入留言内容')
        return
      }
      this.$http.post('project/saveMsg', {
		type: this.radio,  
        projectId: this.Id,
        content: this.textarea
      }).then(res => {
        if (res.code == 200) {
          this.$message.success('留言成功')
          this.textarea = ''
          this.getMesList()
        }
      })
    },
    pageChange(e) {
      this.queryParams.page = e
      this.getMesList()
    },
    getMesList() {
      this.$http.post('project/listMsg', {
        projectId: this.Id,
        pageNum: this.queryParams.page,
        pageSize: this.queryParams.limit
      }).then(res => {
        if (res.code == 200) {
          this.queryParams.totalPage = res.data.pages
          this.msgList = res.data.list
        }
      })
    },
    //下载材料
    downWenjianCg() {
     // this.loading = true
      this.$http.downloadFile('release/batchDownload', {
        id: this.Id
      }).then(res => {
        console.log(res.data)
        var reader = new FileReader();
        let that = this
        reader.onloadend = function (event) {
          //因为返回的报错格式是字符串，手动转换成对象，转换成功表示请求失败
          //转换失败就意味着你拿到的result是文件流，那么直接手动下载就好
          this.loading = false
          console.log(res)
          try {
            let data = JSON.parse(event.target.result);
            console.log(data)
            if (data && data.hasOwnproperty('msg')) {
              that.$message.error(data.msg);
            }
            // that.$message.error(res.data.msg);
          } catch (err) {
            const link = document.createElement('a'); // 创建a标签
            let blob = new Blob([res.data]);
            link.style.display = 'none';
            link.href = URL.createObjectURL(blob); // 创建下载的链接
            link.setAttribute('download', '成果文件' + '.' + 'zip'); // 给下载后的文件命名
            document.body.appendChild(link);
            link.click(); // 点击下载
            document.body.removeChild(link); //  完成移除元素
            window.URL.revokeObjectURL(link.href); // 释放blob对象
            that.materialsShow = false;
          }
        };
        reader.readAsText(res.data);
      }).catch(err => {
        this.loading = false
      })
    },
	
	downItemOnleyChengguo2(item) {
	  this.loading = true
	  this.$http.downloadFile('upload/download', {
	    fileId: item.fileId,
	    projectId: this.Id
	  }).then(res => {
	    var reader = new FileReader();
	    let that = this
	    reader.onloadend = function (event) {
	      //因为返回的报错格式是字符串，手动转换成对象，转换成功表示请求失败
	      //转换失败就意味着你拿到的result是文件流，那么直接手动下载就好
	      this.loading = false
	      console.log(res)
	      try {
	        let data = JSON.parse(event.target.result)
	        that.$message.error(res.data.msg);
	      } catch (err) {
	        const link = document.createElement('a'); // 创建a标签
	        let blob = new Blob([res.data]);
	        link.style.display = 'none';
	        link.href = URL.createObjectURL(blob); // 创建下载的链接
	        link.setAttribute('download', item.fileName); // 给下载后的文件命名
	        document.body.appendChild(link);
	        link.click(); // 点击下载
	        document.body.removeChild(link); //  完成移除元素
	        window.URL.revokeObjectURL(link.href); // 释放blob对象
	        that.materialsShow = false;
	      }
	    };
	    reader.readAsText(res.data);
	  }).catch(err => {
	    this.loading = false
	  })
	},
	
    //下载成果文件
    downFruitFile() {
      this.loading = true
      this.$http.downloadFile('release/batchDownload', {
        id: this.Id
      }).then(res => {
        let _that = this;
        if (res.data.type === 'application/force-download') {
          const link = document.createElement('a'); // 创建a标签
          let blob = new Blob([res.data]);
          link.style.display = 'none';
          link.href = URL.createObjectURL(blob); // 创建下载的链接
          link.setAttribute('download', '成果文件.zip'); // 给下载后的文件命名
          document.body.appendChild(link);
          link.click(); // 点击下载
          document.body.removeChild(link); //  完成移除元素
          window.URL.revokeObjectURL(link.href); // 释放blob对象
          this.loading = false
          _that.materialsShow = false;
        } else {
          // 将blob文件流转换成json
          const reader = new FileReader()
          reader.onload = function (event) {
            let data = JSON.parse(event.target.result);
            if (data && data.hasOwnProperty('msg')) {
              _that.$message.error(data.msg);
            }
          }
          reader.readAsText(res.data)
        }
      }).catch(err => {
        //console.log(err)
        let _that = this;
        if (err.data.type === "application/json") {
          const reader = new FileReader()
          reader.onload = function (event) {
            let data = JSON.parse(event.target.result);
            console.log(data)
            if (data && data.hasOwnProperty('msg')) {
              _that.$message.error(data.msg);
            }
          }
          reader.readAsText(err.data)
        }
        this.loading = false
      })
    },
    //下载补充材料
    downSupplyFile(item) {
      try {
        this.projectInfo.supplyFiles[index].loadStatus = false;
      } catch (error) { }
      this.$http.downloadFile('upload/download', {
        fileId: item.id,
        projectId: this.Id
      }).then(res => {
        console.log(res)
        try {
          this.projectInfo.supplyFiles[index].loadStatus = false;
        } catch (error) { }
        if (res.data.type === 'application/force-download') {
          const link = document.createElement('a'); // 创建a标签
          let blob = new Blob([res.data]);
          link.style.display = 'none';
          link.href = URL.createObjectURL(blob); // 创建下载的链接
          link.setAttribute('download', item.name); // 给下载后的文件命名
          document.body.appendChild(link);
          link.click(); // 点击下载
          document.body.removeChild(link); //  完成移除元素
          window.URL.revokeObjectURL(link.href); // 释放blob对象
          this.loading = false
          this.materialsShow = false;
        } else {
          // 将blob文件流转换成json
          let _that = this;
          const reader = new FileReader()
          reader.onload = function (event) {
            let data = JSON.parse(event.target.result);
            if (data && data.hasOwnProperty('msg')) {
              _that.$message.error(data.msg);
            }
          }
          reader.readAsText(res.data)
        }
      }).catch(err => {
        console.log(err)
        try {
          this.projectInfo.supplyFiles[index].loadStatus = false;
        } catch (error) { }
        //console.log(err)
        let _that = this;
        if (err.data.type === "application/json") {
          const reader = new FileReader()
          reader.onload = function (event) {
            let data = JSON.parse(event.target.result);
            console.log(data)
            if (data && data.hasOwnProperty('msg')) {
              _that.$message.error(data.msg);
            }
          }
          reader.readAsText(err.data)
        }
      })
    },
    //下载附件资料
    downAttachmentFile(item, index) {
      try {
        this.projectInfo.files[index].loadStatus = true;
      } catch (error) { }
      this.$http.downloadFile('upload/download', {
        fileId: item.id,
        projectId: this.Id
      }).then(res => {
        console.log(res)
        try {
          this.projectInfo.files[index].loadStatus = false;
        } catch (error) { }
        if (res.data.type === 'application/force-download') {
          const link = document.createElement('a'); // 创建a标签
          let blob = new Blob([res.data]);
          link.style.display = 'none';
          link.href = URL.createObjectURL(blob); // 创建下载的链接
          link.setAttribute('download', item.name); // 给下载后的文件命名
          document.body.appendChild(link);
          link.click(); // 点击下载
          document.body.removeChild(link); //  完成移除元素
          window.URL.revokeObjectURL(link.href); // 释放blob对象
          this.loading = false
          this.materialsShow = false;
        } else {
          // 将blob文件流转换成json
          let _that = this;
          const reader = new FileReader()
          reader.onload = function (event) {
            let data = JSON.parse(event.target.result);
            if (data && data.hasOwnProperty('msg')) {
              _that.$message.error(data.msg);
            }
          }
          reader.readAsText(res.data)
        }
      }).catch(err => {
        console.log(err)
        try {
          this.projectInfo.files[index].loadStatus = false;
        } catch (error) { }
        //console.log(err)
        let _that = this;
        if (err.data.type === "application/json") {
          const reader = new FileReader()
          reader.onload = function (event) {
            let data = JSON.parse(event.target.result);
            console.log(data)
            if (data && data.hasOwnProperty('msg')) {
              _that.$message.error(data.msg);
            }
          }
          reader.readAsText(err.data)
        }
      })
    },
    downloadFile(item) {
      // if (this.userInfo.identity != 3 && this.projectInfo.fgJurisdiction != 1) {
      //   this.$message.error('暂无权限')
      //   return;
      // }
      this.loading = true
      this.$http.downloadFile('upload/download', {
        fileId: item.id,
        projectId: this.Id
      }).then(res => {
        console.log(res)
        this.loading = false
        var reader = new FileReader();
        let that = this
        reader.onloadend = function (event) {
          //因为返回的报错格式是字符串，手动转换成对象，转换成功表示请求失败
          //转换失败就意味着你拿到的result是文件流，那么直接手动下载就好
          try {
            let data = JSON.parse(event.target.result)
            // console.log(data)
            if (data && data.hasOwnproperty('msg')) {
              that.$message.error(data.msg);
            }

            this.loading = false
          } catch (err) {
            const link = document.createElement('a'); // 创建a标签
            let blob = new Blob([res.data]);
            link.style.display = 'none';
            link.href = URL.createObjectURL(blob); // 创建下载的链接
            link.setAttribute('download', item.name); // 给下载后的文件命名
            document.body.appendChild(link);
            link.click(); // 点击下载
            document.body.removeChild(link); //  完成移除元素
            window.URL.revokeObjectURL(link.href); // 释放blob对象
            this.loading = false
            that.materialsShow = false;
          }
        };
        reader.readAsText(res.data);
      }).catch(err => {
        console.log(err)
        this.loading = false
      })

    },
	
	//打开邀请选择弹框
	openInvites(){
		this.invitesBoxShow = true;
	},
	//yao邀请工程师弹窗
	invitation() {
	  this.engineerNo = "";
	  this.inviteShow = true;
	},
	
	//打开已邀请列表
	openInviresList(){
		this.getBmInvitesList();
		this.invitesListShow = true;
	},
	goToInvites() {
		this.invitesBoxShow = false;
		this.$mHelper.to('/talents')
	},
	
	getBmInvitesList(){
		this.$http.get('director/bmList', {
		  id: this.Id,
		  pageNum: 1,
		  pageSize: 999,
		  status: 1,
		}).then(res => {
		  if (res.code == 200) {
				res.data.list.forEach((e)=>{
					e.rateValue = e.synthesize/20 || 0;
					e.is_fm = 0;
					if(e.team==1){ //有团队
						if(e.orderNum >= 5){
							e.is_fm = 1;
						}
					}else {
						if(e.orderNum >= 2){
							e.is_fm = 1;
						}
					}
					
				})  
		      this.bmInvitesList = res.data.list
		  }
		})
	},
	
	//邀请工程师面板关闭
	handleClose() {
	  this.inviteShow = false;
	  console.log('邀请工程师面板关闭')
	},
	//邀请工程师
	inviteTap() {
	  if (!this.engineerNo) {
	    this.$message.error('请输入工程师编号')
	    return;
	  }
	  this.loading = true;
	  this.$http.post('/director/invitation', {
	    engineerNo: this.engineerNo,
	    projectId: this.Id
	  }).then(res => {
	    this.loading = false
	    if (res.code == 200) {
	      this.inviteShow = false;
	      const h1 = this.$createElement;
	      this.$msgbox({
	        title: '提示',
	        message: h1('p', {
	          style: 'margin-bottom: 20px'
	        }, [
	          h1('span', null, '您已成功邀请工程师申请此项目！ '),
	          h1('br', {
	            style: 'color: red'
	          }, ''),
	          h1('span', null, '如此工程师同意申请，则会出现在项目申请列表中！ '),
	        ]),
	        center: true,
	        showCancelButton: true,
	        showConfirmButton: false,
	        cancelButtonText: '我知道了',
	      })
	    }
	  }).catch(err => {
	    this.loading = false
	  })
	
	},
	tabBmChange(e){
		if(e!=this.bmActive){
			this.bmActive = e;
			this.bmList = [];
			this.bmListGet();
		}
	},
	//报名人员
	bmListGet() {
	  this.$http.get('director/bmList', {
	    id: this.Id,
	    pageNum: 1,
	    pageSize: 999,
		status: this.bmActive,
	  }).then(res => {
	    if (res.code == 200) {
			res.data.list.forEach((e)=>{
				e.rateValue = e.synthesize/20 || 0;
				
				e.is_fm = 0;
				if(e.team==1){ //有团队
					if(e.orderNum >= 5){
						e.is_fm = 1;
					}
				}else {
					if(e.orderNum >= 2){
						e.is_fm = 1;
					}
				}
				
			})  
	        this.bmList = res.data.list
	    }
	  })
	},
	//报名权限
	OpenTakeBack(item, type) {
	  this.$http.post('director/permissionSet', {
	    projectId: this.Id,
	    type: type,
	    engineerId: item.id
	  }).then(res => {
	    if (res.code == 200) {
	      this.$message.success('操作成功')
	      this.bmListGet();
	    }
	  })
	},
	//下拉选择
	handleCommand(e) {
		if(e.type==1){
			//补充附件
			this.supplementShow = true;
		}else if(e.type==3){
			//申请结束项目
			this.cancalgxmCloseShow = true;
		}else if(e.type==8){
			this.cancelOrder();
		}else if(e.type==9){
		  this.complaint = true;
		  this.formLabelAlign.sn = e.projectInfo.sn;
		  this.formLabelAlign.name = e.projectInfo.regionFy + "/" + e.projectInfo.typeFy+'/'+e.projectInfo.majorFy[0].name+'/总投资:￥'+e.projectInfo.totalInvestment+'/总规模:'+e.projectInfo.totalScale+'/'+e.projectInfo.serviceContentFy;
		}else if(e.type==10){
			this.timeShowApply = true;
		}else if(e.type==11){
			const h = this.$createElement;
			this.$msgbox({
			  title: "提示",
			  message: h(
			    "p",
			    {
			      style: "margin-bottom: 20px",
			    },
			    [
			      h(
			        "span",
			        {
			          style: "color: red",
			        },
			        "您确定要发起延期验收吗？ 确认延期验收时间将增加15天！"
			      ),
			    ]
			  ),
			  center: true,
			  showCancelButton: true,
			  confirmButtonText: "确认发起",
			  cancelButtonText: "我再想想",
			  beforeClose: (action, instance, done) => {
			    if (action === "confirm") {
					this.$http.post("release/confirm", {
						id: this.Id,
						type: 2,
						remark: '',
					  }).then((res) => {
						this.loading = false;
						if (res.code == 200) {
							 this.$message.success("延期完成！");
							this.getDetail();
						}
					  })
					  .catch((err) => {
						this.loading = false;
					  });
				
				   done();
			    } else {
			       done();
			    }
			  },
			}).then((action) => {
			    
			});
		}
		
	},
	//关闭投诉弹框
	handleComplaint() {
	  this.complaint = false;
	  this.$refs["fromref"].resetFields();
	},
	//投诉多选
	complaintChange(item) {
	  console.log("item", item);
	},
	checkbox(city) {
	  this.formLabelAlign.check = this.formLabelAlign.check.includes(city)
	    ? [city]
	    : [];
	},
	//提交投诉
	subimtBtn() {
	  this.$refs["fromref"].validate((valid) => {
	    if (valid) {
	      this.formLabelAlign.reason = this.formLabelAlign.check[0];
	      this.$http.post("user/tsProject", this.formLabelAlign).then((res) => {
	        if (res.code == 200) {
	          this.commSucces = true;
	          this.complaint = false;
	          this.$refs["fromref"].resetFields();
			  this.getDetail();
	        }
	      });
	    } else {
	      console.log("error submit!!");
	      return false;
	    }
	  });
	},
	//上传附件请求
	suppleUpdata() {
	  if (this.files.length < 1) {
	    this.$message.error("请上传文件后提交");
	    return;
	  }
	  this.loading = true;
	  this.$http.post("release/supplyFile", {
	      files: this.files,
	      id: this.Id,
	    }).then((res) => {
	      this.loading = false;
	      if (res.code == 200) {
			this.$message.success("您已成功上传补充附件！");
	        this.supplementShow = false;
			this.getDetail();
	      }
	    }).catch((err) => {
	      this.loading = false;
	    });
	},
	onProgress(event, file, fileList) {
	  this.onPrepareFile = true;
	},
	onError(event, file, fileList) {
	  this.onPrepareFile = false;
	  var eFile = file.name || "当前文件";
	  this.$message.error(`${eFile}上传失败，请重试`);
	},
	fujianSuccess(response, file, fileList) {
	  console.log(fileList);
	  this.onPrepareFile = false;
	  let list = [];
	  fileList.forEach((e) => {
	    list.push({
	      name: e.name,
	      url: e.response.data,
	    });
	  });
	  this.files = list;
	  console.log(this.files);
	},
	fujianRemove(file, fileList) {
	  console.log(fileList);
	  let list = [];
	  fileList.forEach((e) => {
	    list.push({
	      name: e.name,
	      url: e.response.data,
	    });
	  });
	  this.files = list;
	  console.log(this.files);
	},
	fujianExceed(files, fileList) {
	  this.$message.warning(`最多选择9张图片`);
	},
	
	cancelOrder() {
	  //请求成功后弹窗
	  const h = this.$createElement;
	  this.$msgbox({
	    title: "提示",
	    message: h(
	      "p",
	      {
	        style: "margin-bottom: 20px",
	      },
	      [
	        h(
	          "span",
	          {
	            style: "color: red",
	          },
	          "您确定要取消此订单吗？ "
	        ),
	      ]
	    ),
	    center: true,
	    showCancelButton: true,
	    confirmButtonText: "确认取消",
	    cancelButtonText: "我再想想",
	    beforeClose: (action, instance, done) => {
	      if (action === "confirm") {
	        instance.confirmButtonLoading = true;
	        instance.confirmButtonText = "执行中...";
	        this.$http.post("project/cancelOrder", {
	            projectId:this.Id,
	            type: 5,
	          }).then((res) => {
	            if (res.code == 200) {
	              done();
	              setTimeout(() => {
	                instance.confirmButtonLoading = false;
	              }, 300);
	            }
	          });
	      } else {
	        done();
	      }
	    },
	  }).then((action) => {
		// const h1 = this.$createElement;
		// this.$msgbox({
		//   title: '取消成功',
		//   message: h1('p', {
		//     style: 'margin-bottom: 20px'
		//   }, [
		//     h1('span', null, '您的项目（项目编号：354656-1）已取消成功，预缴服务费将在一个月内院路退回，请注意查看。'),
		//     h1('br', {
		//       style: 'color: red'
		//     }, ''),
		//     h1('span', null, '若长时间未收到款项，请联系平台：012-132446467！ '),
		//   ]),
		//   center: true,
		//   showCancelButton: true,
		//   showConfirmButton: false,
		//   cancelButtonText: '我已知晓',
		//   type: 'success',
		//   closeOnClickModal: true, //点击空白是否能关闭
		// })   
		  
		  
	    this.getDetail();
	    this.$refs.alert.alertToast(
	      "您已成功取消该项目需求！",
	      "提示",
	      "#ff0000"
	    );
	  });
	},
	//延期申请
	submitYanEvaluate(){
		if (this.formYanApplyData.postponeTime=='') {
		  this.$message.error('请选择延期时间')
		  return;
		}
		if (this.formYanApplyData.cause=='') {
		  this.$message.error('请填写延期原因')
		  return;
		}
		this.loading = true;
		this.$http.post('/release/postpone', {
			isWho: 0,	//0-客户，1-造价师，3-项目经理
			postponeTime: this.formYanApplyData.postponeTime,
			cause: this.formYanApplyData.cause,
			projectId: this.Id,
		}).then(res => {
		  this.loading = false
		  if (res.code == 200) {
		    this.timeShowApply = false;
			this.getDetail();
		    const h1 = this.$createElement;
		    this.$msgbox({
		      title: '提示',
		      message: h1('p', {
		        style: 'margin-bottom: 20px'
		      }, [
		        h1('span', null, '您已成功申请项目延期！ '),
		        h1('br', {
		          style: 'color: red'
		        }, ''),
		        h1('span', null, '请等待项目经理审核！ '),
		      ]),
		      center: true,
		      showCancelButton: true,
		      showConfirmButton: false,
		      cancelButtonText: '我知道了',
		    })
			
			
		  }
		}).catch(err => {
		  this.loading = false
		})
	},
	
	//延期审核
	submitYanApplyEvaluate(){
		if (this.formsYanqiApplyData.postponeTime=='') {
		  this.$message.error('请选择延期时间')
		  return;
		}
		
		if(this.formsYanqiApplyData.type == ""){
			this.$message.error('请选择审核结果')
			return;
		}
		
		if (this.formsYanqiApplyData.type==2 && this.formsYanqiApplyData.managerCase==null) {
		  this.$message.error('请输入不通过原因')
		  return;
		}
		
		if(this.formsYanqiApplyData.type==1){
			this.formsYanqiApplyData.type = 0;
		}else if(this.formsYanqiApplyData.type==2){
			this.formsYanqiApplyData.type = 1;
		}
		
		
		this.loading = true;
		this.$http.post('release/checkPostpone', {
			isWho: 0,	//0-客户，1-造价师，3-项目经理
			id: this.projectInfo.tprojectPostpone.id,
			postponeTime: this.formsYanqiApplyData.postponeTime,
			cause: this.formsYanqiApplyData.managerCase,
			projectId: this.Id,
			type: this.formsYanqiApplyData.type,
		}).then(res => {
		  this.loading = false
		  if (res.code == 200) {
		      this.timeToApply = false;
		     this.$message.success('延期审核完成')
			 this.getDetail();
		  }
		}).catch(err => {
		  this.loading = false
		})
	},
	
	//验收请求
	accepBtns() {
		// if(this.rejectType=='' || this.rejectType==null){
		// 	this.$message.error("请选择审核结果");
		// 	return;
		// }
		// if (this.rejectType==2 && !this.rejectContent) {
		// 	this.$message.error("请输入驳回原因");
		// 	return;
		// }
		 this.rejectType=1;	
	  this.loading = true;
	  this.$http.post("release/confirm", {
	      id: this.Id,
	      type: this.rejectType,
		  remark: this.rejectContent,
	    }).then((res) => {
	      this.loading = false;
	      if (res.code == 200) {
	        this.questionBackApply = false;
			this.rejectContent = "";
	        this.getDetail();
			if(this.rejectType==1){
				const h2 = this.$createElement;
				this.$msgbox({
				  title: "提示",
				  message: h2(
				    "p",
				    {
				      style: "margin-bottom: 20px",
				    },
				    [
				      h2(
				        "span",
				        {
				          style: "color: red",
				        },
				        "确认验收完成 "
				      ),
				    ]
				  ),
				  center: true,
				  showCancelButton: true,
				  showConfirmButton: false,
				  cancelButtonText: '我已知晓',
				  type: 'success',
				  closeOnClickModal: true, //点击空白是否能关闭
				}).then((action) => {
				 
				});
			}else{
				const h2 = this.$createElement;
				this.$msgbox({
				  title: "提示",
				  message: h2(
				    "p",
				    {
				      style: "margin-bottom: 20px",
				    },
				    [
				      h2(
				        "span",
				        {
				          style: "color: red",
				        },
				        "您已驳回此工作成果 "
				      ),
				    ]
				  ),
				  center: true,
				  showCancelButton: true,
				  showConfirmButton: false,
				  cancelButtonText: '我已知晓',
				  type: 'success',
				  closeOnClickModal: true, //点击空白是否能关闭
				}).then((action) => {
				 
				});
			}
	      }
	    })
	    .catch((err) => {
	      this.loading = false;
	    });
	},
	
	//评论弹窗打开
	evalutShowtap(type) {
	  this.evalutType = type;
	  if (type == 1) {
	    this.star1 = 0;
	    this.star2 = 0;
	    this.starText1 = "";
	    this.starText2 = "";
	    this.evalutShow = true;
	  }
	},
	//评论提交
	CommontUpdata() {
	  //
	  if (this.star1 == 0 && this.star2 == 0) {
	    this.$message.error("请选择评分");
	    return;
	  }
	 
	  this.loading = true;
	  this.$http.post("release/evaluate", {
	      projectId: this.Id,
	      attitude: this.star1,
	      quality: this.star2,
	      gcRemark: this.starText1,
	      jlRemark: this.starText2,
	    }).then((res) => {
	      if (res.code == 200) {
	        this.evalutShow = false;
	        this.getDetail();
			const h2 = this.$createElement;
			this.$msgbox({
			  title: "提示",
			  message: h2(
			    "p",
			    {
			      style: "margin-bottom: 20px",
			    },
			    [
			      h2(
			        "span",
			        {
			          style: "color: red",
			        },
			        "评价操作完成 "
			      ),
			    ]
			  ),
			  center: true,
			  showCancelButton: true,
			  showConfirmButton: false,
			  cancelButtonText: '我已知晓',
			  type: 'success',
			  closeOnClickModal: true, //点击空白是否能关闭
			}).then((action) => {
			 
			});
			
	      }
	      this.loading = false;
	    })
	    .catch((err) => {
	      this.loading = false;
	    });
	},
	
	
	surePerson(item){
		this.openSureShow = true;
	},
	onSumbitSure(){
		this.openSureShow = false;
		//this.$message.success('您已确认承接工程师，待工程师缴纳质保金之后即可开始作业。若工程师取消报名或工程师未在4小时内支付质保金，您需重新选择工程师。')
		const h1 = this.$createElement;
		this.$msgbox({
		  title: '操作成功',
		  message: h1('p', {
		    style: 'margin-bottom: 20px'
		  }, [
		    h1('span', null, '您已确认承接工程师！ '),
		    h1('br', {
		      style: 'color: red'
		    }, ''),
		    h1('span', null, '待工程师缴纳质保金之后即可开始作业，若工程师取消报名或工程师未在4小时内支付质保金，您需重新选择工程师！ '),
		  ]),
		  center: true,
		  showCancelButton: true,
		  showConfirmButton: false,
		  cancelButtonText: '我已知晓',
		  type: 'success',
		  closeOnClickModal: true, //点击空白是否能关闭
		}) 
		 
		// this.$confirm("您已确认承接工程师，待工程师缴纳质保金之后即可开始作业。若工程师取消报名或工程师未在4小时内支付质保金，您需重新选择工程师" ,"提示", {
		// 	confirmButtonText: '关闭',
		// 	cancelButtonText: '我已知晓',
		// 	type: 'success',
		// 	center: true,
		// 	showCancelButton: true,
		// 	showConfirmButton: true,
		// 	showClose:false, //是否显示右上角关闭按钮
		// 	closeOnClickModal: true, //点击空白是否能关闭
		// 	customClass:'aaa',
		// }).then(()=>{
		// 	console.log('11111')
		// }).catch(()=>{
		// 	console.log('22222')
		// })
		 
	},
	
	//申请结束项目
	submitCacnlEvaluate(){
		const h2 = this.$createElement;
		this.$confirm("", {
		  title: "提示",
		  message: h2(
		    "p",{ style: "margin-bottom: 20px", },
		    [
		      h2(
		        "span", { style: "color: red",}, "是否确认申请取消项目"
		      ),
		      h2("br", null, ""),
		      h2(
		        "span", { style: "color: red",}, ""
		      ),
		    ]
		  ),
		  center: true,
		  showCancelButton: true,
		  confirmButtonText: "确认申请",
		  cancelButtonText: "我再想想",
		}).then(() => {
		    var durTip = this.$message({
		      duration: 1000,
		      message: h2("p", null, [
		        h2("span", { style: "color: #4077DD" }, "处理中… "),
		      ]),
		    });
		    this.$http.post("project/cancelOrder", {
		        projectId: this.Id,
		        type: 7,
		      }).then((res) => {
		        durTip.close();
		        if (res.code == 200) {
					this.cancalgxmCloseShow = false,
		          this.getDetail();
		           this.$message.success('申请成功')
		        }
		      }).catch((err) => {
		        durTip.close();
		      });
		  }).catch((action) => {
				 durTip.close();
		  });
	}
	
  }
}
</script>

<style lang="scss" scoped>
/*滚动条样式*/
::v-deep ::-webkit-scrollbar {
  width: 10px;
  /*height: 4px;*/
}

::v-deep ::-webkit-scrollbar-thumb {
  border-radius: 2px;
  -webkit-box-shadow: inset 0 0 5px rgba(200, 200, 200, 0.2);
  background: #185BA1;
}

::v-deep ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}


::v-deep  .el-icon-star-on {
		color: #FFB420;
	}
.on-sign-up{
	background: #1677FF;
}
.on-conduct{
	background: #FFC726;
}
.on-completed{
	background: #E8E8E8;
}
.on-success{
	background: #10c550;
}
.lines-title{
	.export{
		font-weight: 400;
		font-size: 14px;
		color: #3B77FF;
		margin-left: 16px;
	}
   .hengxian {
	   width: calc(100% - 86px);
   }
}
.lines-title2{
   .hengxian {
	   width: calc(100% - 186px);
   }
}
.lines-title3{
	.hengxian{
		 width: calc(100% - 170px);
	}
	.span1{
		font-size: 13px;
		cursor: pointer;
	}
	.span2{
		font-size: 13px;
		margin-left: 12px;
		cursor: pointer;
	}
	.active{
		font-size: 16px;
		cursor: auto;
	}
}
	
	.topNavs{
		display: flex;
		justify-content: space-between;
		align-items: center;
		.topMeg{
			display: flex;
			align-items: center;
			img{
				width: 21px;
				height: 21px;
			}
			span{
				font-family: PingFangSC, PingFang SC;
				font-weight: 400;
				font-size: 16px;
				color: #FF4D4F;
				line-height: 21px;
				margin-left: 8px;
			}
		}
	}
	
	.hall-top{
		margin-top: 33px;
		.topSn{
			font-family: PingFangSC, PingFang SC;
			font-weight: 400;
			font-size: 16px;
			color: rgba(0,0,0,0.85);
			line-height: 21px;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		.topTip{
			background: #FFFBE6;
			border-radius: 8px;
			border: 1px solid #FFF1B8;
			padding: 9px 13px;
			margin-top: 17px;
			.topTitle{
				display: flex;
				justify-content: space-between;
				.tipLeft{
					display: flex;
					align-items: center;
					img{
						width: 14px;
						height: 14px;
					}
					span{
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 14px;
						color: rgba(0,0,0,0.88);
						line-height: 22px;
						margin-left: 8px;
						white-space: normal;
						word-break: break-all;
					}
				}
				.tipRight{
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 14px;
					color: #1677FF;
					line-height: 22px;
				}
			}
			.tipInfo{
				font-family: PingFangSC, PingFang SC;
				font-weight: 500;
				font-size: 14px;
				color: rgba(0,0,0,0.88);
				line-height: 22px;
				margin-top: 8px;
			}
		}
		.top-content{
			display: flex;
			align-items: center;
			justify-content: space-between;
			.left{
				width: calc(100% - 400px);
				.topNames{
					font-family: PingFangSC, PingFang SC;
					font-weight: 500;
					font-size: 18px;
					color: rgba(0,0,0,0.85);
					line-height: 21px;
					margin-top: 17px;
					display: flex;
					align-items: center;
					.topState{
						padding: 0.5px 4px;
						border-radius: 4px;
						font-size: 10px;
						color: #FFFFFF;
						text-align: center;
						margin-right: 8px;
					}
				}
				.topTagsBox{
					margin-top: 16px;
					.tags{
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 14px;
						color: #4077DD;
						line-height: 21px;
						text-align: left;
						font-style: normal;
						margin-right: 16px;
						position: relative;
						&:before{
							content: '';
							width: 1px;
							height: 12px;
							background-color: #4077DD;
							position: absolute;
							top: 5px;
							right: -8px;
						}
						&:last-child{
							&:before{
								display: none;
							}
						}
					}
				}
				.topPrice{
					font-family: PingFangSC, PingFang SC;
					font-weight: 600;
					font-size: 18px;
					color: #FF4D4F;
					line-height: 36px;
					text-align: left;
					font-style: normal;
					margin-top: 24px;
				}
				.topTime{
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: rgba(0,0,0,0.85);
					line-height: 21px;
					text-align: left;
					font-style: normal;
					margin-top: 25px;
					.yq-time{
						background: #FFFFFF;
						border-radius: 4px;
						border: 1px solid #4077DD;
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 12px;
						color: #4077DD;
						line-height: 21px;
						padding: 0 4px;
						margin-left: 16px;
					}
				}
				
			}
			.right{
				width: 338px;
				display: flex;
				justify-content: flex-end;
				.right-type-box1{
					display: flex;
					flex-flow: column;
					align-items: flex-end;
					.type-btn{
						width: 230px;
						height: 60px;
						background: #FF4D4F;
						border-radius: 8px;
						display: flex;
						justify-content: center;
						align-items: center;
						img{
							width: 20px;
							height: 20px;
						}
						span{
							font-family: PingFangSC, PingFang SC;
							font-weight: 400;
							font-size: 16px;
							color: #FFFFFF;
							line-height: 24px;
							text-align: center;
							font-style: normal;
							margin-left: 8px;
						}
					}
					.type-number{
						width: 230px;
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 16px;
						color: #4077DD;
						line-height: 21px;
						text-align: center;
						font-style: normal;
						margin-top: 12px;
					}
				}
				.right-rate-box1{
					display: flex;
					justify-content: flex-end;
					.rate-list{
						display: flex;
						flex-flow: column;
						align-items: flex-end;
						.item-rate{
							display: flex;
							align-items: center;
							margin-bottom: 12px;
							.item-title{
								width: 98px;
								font-family: PingFangSC, PingFang SC;
								font-weight: 400;
								font-size: 14px;
								color: rgba(0,0,0,0.85);
								line-height: 39px;
								text-align: right;
							}
							::v-deep .el-rate{
								height: auto;
								.el-rate__icon {
								  font-size: 30px !important;
								}
							}
						}
						.type-btn{
							width: 230px;
							height: 60px;
							background: #4077DD;
							border-radius: 8px;
							font-family: PingFangSC, PingFang SC;
							font-weight: 400;
							font-size: 16px;
							color: #FFFFFF;
							line-height: 24px;
							text-align: center;
							font-style: normal;
							display: flex;
							justify-content: center;
							align-items: center;
							position: relative;
							margin-top: 4px;
							.top-left-tabs{
								width: 75px;
								height: 32.5px;
								position: absolute;
								top: 0;
								left: 0;
							}
						}
					}
				}
				
				.right-type-box2{
					display: flex;
					justify-content: flex-end;
					.right-btn{
						width: 120px;
						height: 60px;
						border-radius: 8px;
						border: 1px solid #4077DD;
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 16px;
						color: #4077DD;
						line-height: 24px;
						display: flex;
						justify-content: center;
						align-items: center;
						margin-right: 8px;
					}
					::v-deep .el-dropdown{
						height: 60px;
					}
					.right-two-box{
						.type-btn{
							width: 230px;
							height: 60px;
							background: #4077DD;
							border-radius: 8px;
							font-family: PingFangSC, PingFang SC;
							font-weight: 400;
							font-size: 16px;
							color: #FFFFFF;
							line-height: 24px;
							text-align: center;
							font-style: normal;
							display: flex;
							justify-content: center;
							align-items: center;
							position: relative;
							.top-left-tabs{
								width: 75px;
								height: 32.5px;
								position: absolute;
								top: 0;
								left: 0;
							}
						}
						.type-default{
							background-color: #e7e7e7;
							border-color: #e7e7e7;
						}
						.type-number{
							margin-top: 12px;
							width: 230px;
							display: flex;
							align-items: center;
							justify-content: center;
							img{
								width: 20px;
								height: 20px;
							}
							span{
								font-family: PingFangSC, PingFang SC;
								font-weight: 400;
								font-size: 16px;
								color: #FF4D4F;
								line-height: 21px;
								text-align: left;
								font-style: normal;
								margin-left: 8px;
							}
						}
						.type-back{
							font-family: PingFangSC, PingFang SC;
							font-weight: 400;
							font-size: 16px;
							color: rgba(0,0,0,0.45);
							line-height: 21px;
							text-align: left;
							font-style: normal;
							text-decoration-line: underline;
						}
					}
				}
				.right-over-box1{
					width: 283px;
					.over-item{
						display: flex;
						justify-content: space-between;
						align-items: center;
						
							font-family: PingFangSC, PingFang SC;
							font-weight: 500;
							font-size: 18px;
							color: rgba(0,0,0,0.85);
							line-height: 36px;
						
					}
				}
			}
		}
		
	}
	.hall-box{
		display: flex;
		justify-content: space-between;
		margin-top: 64px;
		margin-bottom: 32px;
		.left{
			width: calc(100% - 346px);
			.left-box-1{
				.left-title{
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: rgba(0,0,0,0.45);
					line-height: 21px;
					text-align: left;
					font-style: normal;
					margin-top: 24px;
				}
				.btLines{
					margin-top: 14px;
					.tag-title{
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 16px;
						color: rgba(0,0,0,0.85);
						line-height: 21px;
						text-align: left;
						font-style: normal;
					}
					::v-deep .el-tag{
						height: auto;
						padding: 3.34px 7.34px;
						background: #F5F7FA;
						border-radius: 4px;
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 14px;
						color: #555C70;
						line-height: 20px;
						text-align: left;
						font-style: normal;
						margin-bottom: 10px;
						margin-right: 8px;
					}
				}
				.miaoshu{
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: rgba(0,0,0,0.85);
					line-height: 21px;
					text-align: left;
					font-style: normal;
					margin-top: 12px;
				}
			}
			.left-box-2{
				.left-list{
					margin-top: 24px;
					.item{
						img{
							width: 72px;
							height: 68px;
						}
						.item-title{
							width: 96px;
							height: 21px;
							font-family: PingFangSC, PingFang SC;
							font-weight: 400;
							font-size: 16px;
							color: rgba(0,0,0,0.85);
							line-height: 21px;
							text-align: left;
							font-style: normal;
							margin-top: 12px;
							white-space: normal;
							word-break: break-all;
						}
					}
				}
			}
			.left-box-3{
				.item-invites{
					justify-content: space-between;
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					color: rgba(0,0,0,0.85);
					margin-top: 24px;
					.left{
						width: calc(100% - 150px);
						.user-invites{
							margin-left: 36px;
							.invits-top{
								.username{
									font-size: 16px;
									line-height: 21px;
								}
								.userTag{
									padding: 0px 3.34px;
									border-radius: 4px;
									border: 1px solid #4077DD;
									font-size: 12px;
									color: #4077DD;
									line-height: 21px;
									margin-left: 8px;
								}
								::v-deep .el-rate{
									margin-left: 24px;
								}
							}
							.invites-sn{
								font-size: 14px;
								margin-top: 12px;
							}
							.invites-tags{
								margin-top: 6px;
								line-height: 30px;
								span{
									width: 89px;
									height: 22px;
									background: #F5F7FA;
									border-radius: 4px;
									font-size: 12px;
									color: #555C70;
									line-height: 20px;
									margin-right: 8px;
									padding: 3px 8px;
								}
							}
							.invites-fen{
								margin-top: 12px;
								.invites_lines{
									margin-right: 24px;
									.numbers{
										font-size: 14px;
									}
									.text{
										font-size: 12px;
									}
								}
							}
						}
					}
					.right{
						.btn-default{
							height: 40px;
							border-radius: 6px;
							border: 1px solid #4077DD;
							background: #FFFFFF;
							font-family: PingFangSC, PingFang SC;
							font-weight: 400;
							font-size: 16px;
							color: #4077DD;
							line-height: 24px;
						}
						.btn-error{
							height: 40px;
							border-radius: 6px;
							border: 1px solid #FF4D4F;
							background: #FFFFFF;
							font-family: PingFangSC, PingFang SC;
							font-weight: 400;
							font-size: 16px;
							color: #FF4D4F;
							line-height: 24px;
						}
					}
				}
			}
			.left-box-4{
				min-height: 211px;
				background: #FFFFFF;
				border-radius: 8px;
				border: 1px solid #E8E8E8;
				.jd-item{
					.jd-top{
						height: 68px;
						background: #F5FAFF;
						border-radius: 8px 8px 0px 0px;
						border: 1px solid #E8E8E8;
						padding: 0 24px;
						display: flex;
						justify-content: space-between;
						align-items: center;
						span{
							font-family: PingFangSC, PingFang SC;
							font-weight: 500;
							font-size: 16px;
							color: rgba(0,0,0,0.85);
							line-height: 21px;
						}
					}
					.jd-cont{
						padding:24px;
						.cont-item{
							display: flex;
							.number{
								width: 24px;
								height: 24px;
								background: #1677FF;
								border-radius: 12px;
								font-family: PingFangSC, PingFang SC;
								font-weight: 400;
								font-size: 14px;
								color: #FFFFFF;
								line-height: 22px;
								display: flex;
								justify-content: center;
								align-items: center;
								position: relative;
							}
							.number2{
								background: rgba(0,0,0,0.06);
								color: rgba(0,0,0,0.45);
							}
							.number3{
								background: rgba(255,77,79,0.1);
								color: #FF4D4F;
							}
							.number4{
								background: #E6F4FF;
								color: #1677FF;
								&::after{
									box-sizing: content-box;
									content: "";
									border: 1px solid #1677FF;
									border-left: 0;
									border-top: 0;
									height: 7px;
									left: 10px;
									position: absolute;
									top: 7px;
									transform: rotate(45deg) scaleY(1);
									width: 3px;
									transition: transform .15s ease-in 50ms;
									transform-origin: center;
								}
							}
							
							
							.title{
								font-family: PingFangSC, PingFang SC;
								font-weight: 500;
								font-size: 16px;
								color: rgba(0,0,0,0.85);
								line-height: 24px;
								margin-left: 16px;
							}
							.info{
								font-family: PingFangSC, PingFang SC;
								font-weight: 400;
								font-size: 14px;
								color: rgba(0,0,0,0.85);
								line-height: 24px;
								margin-left: 16px;
							}
							.backColor{
								color: rgba(0,0,0,0.25);
							}
						}
						.shuxian{
							width: 1px;
							height: 36px;
							background: rgba(0,0,0,0.15);
							margin-left: 12px;
							margin-top: 4px;
							margin-bottom: 4px;
						}
					}
					
				}
			}
			
		}
		.right{
			.right-box-1{
				width: 300px;
				min-height: 241px;
				background: #FFFFFF;
				border-radius: 8px;
				border: 1px solid #E8E8E8;
				padding-bottom: 24px;
				.right-title{
					font-family: PingFangSC, PingFang SC;
					font-weight: 600;
					font-size: 16px;
					color: rgba(0,0,0,0.85);
					line-height: 21px;
					text-align: left;
					font-style: normal;
					margin-top: 24px;
					padding: 0 24px;
				}
				.right-user{
					display: flex;
					align-items: center;
					margin-top: 24px;
					padding: 0 24px;
					::v-deep .el-image{
						img{
							width: 42px;
							height: 42px;
							border-radius: 50%;
						}
					}
					.right-uinfo{
						margin-left: 16px;
						.name{
							font-family: PingFangSC, PingFang SC;
							font-weight: 400;
							font-size: 16px;
							color: rgba(0,0,0,0.85);
							line-height: 21px;
							text-align: left;
							font-style: normal;
						}
						.rStatis{
							width: 64px;
							height: 28px;
							border-radius: 4px;
							margin-top: 4px;
							font-family: HYk1gj;
							font-size: 12px;
							color: #FFFFFF;
							line-height: 20px;
							display: flex;
							justify-content: center;
							align-items: center;
						}
						.right-wrz{
							background: linear-gradient(180deg, #e2e2e2 2%, #eceae9 100%);
							box-shadow: 0px 0px 4px 0px rgb(225 221 217 / 59%);
						}
						.right-grrz{
							background: linear-gradient( 180deg, #0D9AF6 0%, #267CFC 100%);
							box-shadow: 0px 0px 4px 0px rgba(43,104,255,0.59);
						}
						.right-qyrz{
							background: linear-gradient( 180deg, #F6A00D 2%, #FC8526 100%);
							box-shadow: 0px 0px 4px 0px rgba(255,140,43,0.59);
						}
					}
				}
				.right-phone{
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: rgba(0,0,0,0.85);
					line-height: 21px;
					text-align: left;
					font-style: normal;
					margin-top: 24px;
					padding: 0 24px;
					white-space: normal;
					word-break: break-all;
				}
				.right-time{
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: rgba(0,0,0,0.45);
					line-height: 21px;
					text-align: left;
					font-style: normal;
					margin-top: 24px;
					padding: 0 24px;
				}
			}
			.right-box-2{
				width: 300px;
				min-height: 156px;
				background: #FFFFFF;
				border-radius: 8px;
				border: 1px solid #E8E8E8;
				margin-top: 24px;
				padding-bottom: 24px;
			}
			.right-box-guo{
				width: 300px;
				height: 259px;
				background: #FFFFFF;
				border-radius: 8px;
				border: 1px solid #E8E8E8;
				margin-bottom: 24px;
				.top-guo{
					padding: 0 24px;
					justify-content: space-between;
					align-items: center;
					margin-top: 24px;
					.name{
						font-family: PingFangSC, PingFang SC;
						font-weight: 500;
						font-size: 16px;
						color: rgba(0,0,0,0.85);
						line-height: 21px;
						text-align: left;
						font-style: normal;
					}
					.jieguoBtn{
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 16px;
						color: #4077DD;
						line-height: 36px;
						text-align: right;
						font-style: normal;
					}
				}
				.times{
					padding: 0 24px;
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 12px;
					color: rgba(0,0,0,0.45);
					line-height: 17px;
					text-align: left;
					font-style: normal;
					margin-top: 12px;
				}
				img{
					width: 58px;
					height: 58px;
					margin: auto;
					display: flex;
					margin-top: 29px;
				}
				.title{
					padding: 0 24px;
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: rgba(0,0,0,0.85);
					line-height: 24px;
					text-align: center;
					font-style: normal;
					margin-top: 3px;
				}
			}
			.right-box-apply{
				width: 300px;
				min-height: 224px;
				background: #FFFFFF;
				border-radius: 8px;
				border: 1px solid #E8E8E8;
				margin-bottom: 24px;
				.apply-title{
					padding: 24px 24px 0;
					font-family: PingFangSC, PingFang SC;
					font-weight: 600;
					font-size: 16px;
					color: rgba(0,0,0,0.85);
					line-height: 21px;
				}
				.apply-info{
					padding:  0 24px;
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: rgba(0,0,0,0.85);
					line-height: 21px;
					margin-top: 24px;
				}
				.apply-cont{
					padding:  0 24px;
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: rgba(0,0,0,0.85);
					line-height: 21px;
					margin-top: 12px;
					white-space: normal;
					word-break: break-all;
				}
				.apply-memver{
					padding:  0 24px;
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: #FF4D4F;
					line-height: 21px;
					margin-top: 12px;
				}
				.apply-btn{
					width: 252px;
					height: 32px;
					background: #4077DD;
					border-radius: 8px;
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: #FFFFFF;
					display: flex;
					justify-content: center;
					align-items: center;
					margin: 12px auto;
				}
			}
		}
	}
	
	
	.tipPopup{
		::v-deep .el-dialog{
			box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1), 0px 0px 5px 0px rgba(0,0,0,0.14);
			border-radius: 10px;
			.el-dialog__header{
				padding: 20px 36px 0 28px;
				.el-dialog__title{
					font-family: PingFangSC, PingFang SC;
					font-weight: 500;
					font-size: 18px;
					color: rgba(0,0,0,0.85);
					line-height: 25px;
					text-align: left;
					font-style: normal;
				}
			}
			.dialog-bom{
				.el-button{
					width: 96px;
					height: 40px;
					background: #4077DD;
					border-radius: 8px;
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: #FFFFFF;
					line-height: 24px;
					font-style: normal;
					display: flex;
					justify-content: center;
					align-items: center;
				}
				.close{
					background: #FFFFFF;
					border-radius: 6px;
					border: 1px solid #4077DD;
					color: #4077DD;
				}
			}
			.fp-left{
				font-family: PingFangSC, PingFang SC;
				font-weight: 500;
				font-size: 16px;
				color: rgba(0,0,0,0.85);
				line-height: 21px;
				text-align: left;
				font-style: normal;
				margin-bottom: 12px;
			}
		}
	}
	
	.look-box{
		::v-deep .el-dialog{
			width: 700px;
			// min-height: 893px;
			background: #FFFFFF;
			box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1), 0px 0px 5px 0px rgba(0,0,0,0.14);
			border-radius: 10px;
			padding: 20px 32px 32px;
			.el-dialog__header{
				padding: 0;
			}
			.el-dialog__body{
				padding: 0;	
			}
		}
		.title{
			font-family: PingFangSC, PingFang SC;
			font-weight: 500;
			font-size: 18px;
			color: #FF4D4F;
			line-height: 36px;
			text-align: left;
			font-style: normal;
		}
		.look-title{
			font-family: PingFangSC, PingFang SC;
			font-weight: 500;
			font-size: 18px;
			color: rgba(0,0,0,0.85);
			line-height: 36px;
			text-align: left;
			font-style: normal;
			margin-bottom: 8px;
		}
		.look-bbx{
			font-family: PingFangSC, PingFang SC;
			font-weight: 500;
			font-size: 18px;
			color: rgba(0,0,0,0.85);
			line-height: 36px;
			text-align: left;
			font-style: normal;
			.m-flex{
				justify-content: space-between;
				position: relative;
				img{
					width: 14px;
					height: 14px;
				}
			}
			.lines-next{
				width: 100%;
				height: 1px;
				background-color: #E8E8E8;
				margin-top: 17px;
				margin-bottom: 9px;
			}
			.color1{
				color: rgba(0,0,0,0.85);
			}
			.color2{
				color: #FF4D4F;
			}
			.color3{
				color: #4077DD;
			}
			
		}
	}
	
	.ts-dbox{
		.ts-title{
			font-family: PingFangSC, PingFang SC;
			font-weight: 500;
			font-size: 18px;
			color: rgba(0,0,0,0.85);
			line-height: 39px;
			text-align: left;
			font-style: normal;
		}
		.ts-info{
			font-family: PingFangSC, PingFang SC;
			font-weight: 400;
			font-size: 16px;
			color: rgba(0,0,0,0.45);
			line-height: 22px;
			margin-top: 14px;
		}
		.ts-content{
			font-family: PingFangSC, PingFang SC;
			font-weight: 400;
			font-size: 16px;
			color: rgba(0,0,0,0.85);
			line-height: 22px;
			margin-top: 16px;
		}
	}

	.project-jz{
		min-height: 250px;
		.jz-list{
			margin-top: 24px;
			.item{
				display: flex;
				justify-content: space-between;
				align-items: center;
				border-bottom: 1px solid #F5F7FA;
				height: 40px;
				.title{
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: rgba(0,0,0,0.85);
					line-height: 21px;
					text-align: left;
					font-style: normal;
				}
				.times{
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 16px;
					color: rgba(0,0,0,0.85);
					line-height: 21px;
					text-align: left;
					font-style: normal;
				}
			}
		}
	}


.left-box-invites{
	width: 100%;
	height: 555px;
	overflow-y: scroll;
	overflow-x: hidden;
	.item-invites{
		justify-content: space-between;
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		color: rgba(0,0,0,0.85);
		margin-top: 24px;
		.left{
			width: calc(100% - 150px);
			.user-invites{
				margin-left: 36px;
				.invits-top{
					.username{
						font-size: 16px;
						line-height: 21px;
					}
					.userTag{
						padding: 0px 3.34px;
						border-radius: 4px;
						border: 1px solid #4077DD;
						font-size: 12px;
						color: #4077DD;
						line-height: 21px;
						margin-left: 8px;
					}
					::v-deep .el-rate{
						margin-left: 24px;
					}
				}
				.invites-sn{
					font-size: 14px;
					margin-top: 12px;
				}
				.invites-tags{
					margin-top: 6px;
					line-height: 30px;
					span{
						width: 89px;
						height: 22px;
						background: #F5F7FA;
						border-radius: 4px;
						font-size: 12px;
						color: #555C70;
						line-height: 20px;
						margin-right: 8px;
						padding: 3px 8px;
					}
				}
				.invites-fen{
					margin-top: 12px;
					.invites_lines{
						margin-right: 24px;
						.numbers{
							font-size: 14px;
						}
						.text{
							font-size: 12px;
						}
					}
				}
			}
		}
		.right{
			padding-right: 10px;
		}
	}
}


</style>
